import { deleteAction } from '@/command/netTool'
import request from '@/utils/request'

const userApi = {
  login: '/auth/oauth/token', // 登入
  Logout: '/auth/token/logout', // 登出
  ForgePassword: '/auth/forge-password', // 忘记密码
  Register: '/auth/register', // 注册
  twoStepCode: '/auth/2step-code',
  SendSms: '/account/sms',
  SendSmsErr: '/account/sms_err',
  // get my info
  UserInfo: '/user/info',
  UserMenu: '/user/nav',
  getCode: '/admin/account/firm/send', // 获取登录验证码
  getCodeFind: '/admin/account/firm/sendFind', // 获取找回密码验证码
  updatePwd: '/admin/account/firm/phone/changePwd',
  loginByCode: '/auth/social/token/mobile' // 验证码登录
}

export function testRESTFul(param) {
  return request({
    url: `/user/${param.id}`
  })
}
export function passwordLogin(param) {
  return request({
    url: userApi.login,
    method: 'post',
    data: param
  })
}

/**
 * get user 2step code open?
 * @param parameter {*}
 */
export function get2step(parameter) {
  return request({
    url: userApi.twoStepCode,
    method: 'post',
    data: parameter
  })
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login(parameter) {
  return new Promise((resolve, reject) => {
    fetch(`/bpi${userApi.login}?password=${parameter.password}&username=${parameter.username}&grant_type=password`, {
      method: 'POST',
      headers: {
        Authorization: 'Basic enRlc2E6enRlc2E='
      }
    })
      .then(e => e.json())
      .then(e => resolve({ result: e }))
  })
}

/**
 * loginByCode func
 * parameter: {
 *     mobile: '',
 *     code: '',
 * }
 * @param parameter
 * @returns {*}
 */
export function loginByCode(parameter) {
  return new Promise((resolve, reject) => {
    fetch(`/bpi${userApi.loginByCode}?mobile=${parameter.phoneNumber}&code=${parameter.code}`, {
      method: 'POST',
      headers: {
        Authorization: 'Basic enRlc2E6enRlc2E='
      }
    })
      .then(e => e.json())
      .then(e => resolve({ result: e }))
  })
}

/**
 * getCode func
 * parameter: {
 *     phone: '',
 * }
 * @description 获取登录验证码
 * @param parameter
 * @returns {*}
 */
export function getCode(parameter) {
  return new Promise((resolve, reject) => {
    fetch(`/bpi${userApi.getCode}/${parameter.phoneNumber}`, {
      method: 'GET',
      headers: {
        Authorization: 'Basic enRlc2E6enRlc2E='
      }
    })
      .then(e => e.json())
      .then(e => resolve({ result: e }))
  })
}

/**
 * getCodeFind func
 * parameter: {
 *     phone: '',
 * }
 * @description 获取修改密码验证码
 * @param parameter
 * @returns {*}
 */
export function getCodeFind(parameter) {
  return new Promise((resolve, reject) => {
    fetch(`/bpi${userApi.getCodeFind}/${parameter.phoneNumber}`, {
      method: 'GET',
      headers: {
        Authorization: 'Basic enRlc2E6enRlc2E='
      }
    })
      .then(e => e.json())
      .then(e => resolve({ result: e }))
  })
}

/**
 * updatePwd func
 * parameter: {
 *     code: '',
 *     password: '',
 *     phone: '',
 * }
 * @description 找回修改密码
 * @param parameter
 * @returns {*}
 */
export function updatePwd(parameter) {
  return new Promise((resolve, reject) => {
    fetch(`/bpi${userApi.updatePwd}`, {
      method: 'PUT',
      body: JSON.stringify(parameter),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
      .then(e => e.json())
      .then(e => resolve({ result: e }))
  })
}

export function getSmsCaptcha(parameter) {
  return request({
    url: userApi.SendSms,
    method: 'post',
    data: parameter
  })
}

export function getInfo() {
  return request({
    url: userApi.UserInfo,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function getCurrentUserNav() {
  return request({
    url: userApi.UserMenu,
    method: 'get'
  })
}

export function logout() {
  return deleteAction(userApi.Logout)
}

export const getFarmGoodsGroupPeoplePagePeople = function getFarmGoodsGroupPeoplePagePeople({
  id = this.tabsSelectId,
  current = this.tabsCurrent,
  isClear = false,
  type = this.tabsType
} = {}) {
  getAction('/farmGoodsGroupPeople/pagePeople').then(e => {
    this.tabsCurrent = current
    this.tabsSelectId = id
    this.tabsSelectType = type
    if (isClear) {
      this.tabsList = e.records
    } else {
      this.tabsList = [].concat(this.tabsList, e.records)
    }
  })
}
