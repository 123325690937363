import { BasicLayout } from '@/layouts'
import { order, configuration, goods, audit, marketing, jibenxinxi } from '@/core/icons'

const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view'),
}

let publicRouter = function () {
  return [
    {
      path: '/marketingCenter/setMain',
      name: 'setMain',
      hidden: true,
      component: () => import('@/views/marketingCenter/bigWheel/setMain'),
      meta: {
        title: '设置主体',
        keepAlive: false,
        permission: ['dashboard'],
      },
    },
    {
      path: '/userinforsetting/personalsetting',
      name: 'personalsetting',
      hidden: true,
      component: () => import('@/views/userinforsetting/personalsetting/index'),
      meta: {
        title: '个人信息设置',
        keepAlive: false,
        permission: ['dashboard'],
      },
    },
  ]
}

let dataReport = function () {
  return [
    {
      path: '/dataReport/platform',
      name: 'dataReportPlatform',
      component: () => import('@/views/dataReport/platform.vue'),
      meta: {
        title: '平台报表',
        keepAlive: false,
        permission: ['dashboard'],
        name: 'platform',
      },
    },
    {
      path: '/dataReport/platformDetail',
      name: 'platformDetail',
      hidden: true,
      component: () => import('@/views/dataReport/platformDetail.vue'),
      meta: {
        title: '平台报表详情',
        keepAlive: false,
        permission: ['dashboard'],
      },
    },
    {
      path: '/dataReport/business',
      name: 'business',
      component: () => import('@/views/dataReport/business.vue'),
      meta: {
        title: '商户报表',
        keepAlive: false,
        permission: ['dashboard'],
        name: 'business',
      },
    },
    {
      path: '/dataReport/businessDetail',
      name: 'businessDetail',
      hidden: true,
      component: () => import('@/views/dataReport/businessDetail.vue'),
      meta: {
        title: '商户报表详情',
        keepAlive: false,
        permission: ['dashboard'],
      },
    },
    {
      path: '/dataReport/orderFlow',
      name: 'orderFlow',
      component: () => import('@/views/dataReport/orderFlow.vue'),
      meta: {
        title: '商户交易订单流水',
        keepAlive: false,
        permission: ['dashboard'],
        name: 'orderFlow',
      },
    },
    {
      path: '/dataReport/tradeStatistics',
      name: 'tradeStatistics',
      component: () => import('@/views/dataReport/tradeStatistics.vue'),
      meta: {
        title: '商户交易统计报表',
        keepAlive: false,
        permission: ['dashboard'],
        name: 'tradeStatistics',
      },
    },
    {
      path: '/dataReport/dsPlatform',
      name: 'dsPlatform',
      component: () => import('@/views/dataReport/dsPlatform.vue'),
      meta: {
        title: '岱山平台报表',
        keepAlive: false,
        permission: ['dashboard'],
        name: 'dsPlatform',
      },
    },
    {
      path: '/dataReport/scenic',
      name: 'dataReportScenic',
      component: () => import('@/views/dataReport/scenic'),
      meta: {
        title: '景区报表',
        keepAlive: false,
        permission: ['dashboard'],
        name: 'dataReportScenic',
      },
    },
    {
      path: '/dataReport/scenicDetail',
      name: 'dataReportscenicDetail',
      hidden: true,
      component: () => import('@/views/dataReport/scenicDetail.vue'),
      meta: {
        title: '景区报表详情',
        keepAlive: false,
        permission: ['dashboard'],
      },
    },
    {
      path: '/dataReport/hotelStatement',
      name: 'dataReporthotelStatement',
      component: () => import('@/views/dataReport/hotelStatement.vue'),
      meta: {
        title: '酒店报表',
        keepAlive: false,
        permission: ['dashboard'],
        name: 'dataReporthotelStatement',
      },
    },
    {
      path: '/dataReport/hotelStatementDetail',
      name: 'dataReporthotelStatementDetail',
      hidden: true,
      component: () => import('@/views/dataReport/hotelStatementDetail.vue'),
      meta: {
        title: '酒店报表详情',
        keepAlive: false,
        permission: ['dashboard'],
      },
    },
    {
      path: '/dataReport/productReport',
      name: 'dataReportproductReport',
      component: () => import('@/views/dataReport/productReport.vue'),
      meta: {
        title: '特产报表',
        keepAlive: false,
        permission: ['dashboard'],
        name: 'dataReportproductReport',
      },
    },
    {
      path: '/dataReport/productReportDetail',
      name: 'productReportDetail',
      hidden: true,
      component: () => import('@/views/dataReport/productReportDetail.vue'),
      meta: {
        title: '特产报表详情',
        keepAlive: false,
        permission: ['dashboard'],
      },
    },
    {
      path: '/dataReport/verificationStatement',
      name: 'dataReportverificationStatement',
      component: () => import('@/views/dataReport/verificationStatement.vue'),
      meta: {
        title: '核销报表',
        keepAlive: false,
        permission: ['dashboard'],
      },
    },
    {
      path: '/dataReport/verificationStatementDetail',
      name: 'verificationStatementDetail',
      component: () => import('@/views/dataReport/verificationStatementDetail.vue'),
      hidden: true,
      meta: {
        title: '核销报详情',
        keepAlive: false,
        permission: ['dashboard'],
      },
    },
  ]
}

// 商品管理详情页面
let aSubject = function () {
  return [
    {
      path: '/commodityManagement/entityInformationDetail',
      name: 'entityInformationDetail',
      hidden: true,
      component: () => import('@/views/commodityManagement/entityInformation/detail'),
      meta: {
        title: '主体信息详情',
        keepAlive: false,
        permission: ['dashboard'],
      },
    },
    {
      path: '/commodityManagement/entityInformationStoreSettings',
      name: 'entityInformationStoreSettings',
      hidden: true,
      component: () => import('@/views/commodityManagement/entityInformation/storeSettings'),
      meta: {
        title: '店铺设置',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/commodityManagement/entityInformation',
            breadcrumbName: '主体信息',
          },
        ],
      },
    },
    {
      path: '/commodityManagement/specialtyDetail',
      name: 'specialtyDetail',
      hidden: true,
      component: () => import('@/views/commodityManagement/specialty/detail'),
      meta: {
        title: '农家特产详情',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/commodityManagement/specialty',
            breadcrumbName: '农家特产',
          },
        ],
      },
    },
    {
      path: '/commodityManagement/specialtyGoods',
      name: 'specialtyGoods',
      component: () => import('@/views/commodityManagement/specialty/specialtyGoods'),
      hidden: true,
      meta: {
        title: '同步商品',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/commodityManagement/specialty',
            breadcrumbName: '农家特产',
          },
        ],
      },
    },
    {
      path: '/commodityManagement/specialtyGoodsSynchronous',
      name: 'specialtyGoodsSynchronous',
      component: () => import('@/views/commodityManagement/specialty/specialtyGoodsSynchronous'),
      hidden: true,
      meta: {
        title: '同步列表',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/commodityManagement/specialty',
            breadcrumbName: '农家特产',
          },
          {
            path: '/commodityManagement/specialtyGoods',
            breadcrumbName: '同步商品',
          },
        ],
      },
    },
    {
      path: '/commodityManagement/eatDetail',
      name: 'eatDetail',
      hidden: true,
      component: () => import('@/views/commodityManagement/eat/detail'),
      meta: {
        title: '农家乐详情',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/commodityManagement/eat',
            breadcrumbName: '农家乐',
          },
        ],
      },
    },
    {
      path: '/commodityManagement/liveDetail',
      name: 'liveDetail',
      hidden: true,
      component: () => import('@/views/commodityManagement/live/detail'),
      meta: {
        title: '民宿农舍详情',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/commodityManagement/live',
            breadcrumbName: '民宿农舍',
          },
        ],
      },
    },
    {
      path: '/commodityManagement/farmingDetail',
      name: 'farmingDetail',
      hidden: true,
      component: () => import('@/views/commodityManagement/farming/detail'),
      meta: {
        title: '农事体验详情',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/commodityManagement/farming',
            breadcrumbName: '农事体验',
          },
        ],
      },
    },
    {
      path: '/commodityManagement/scenicAreasDetail',
      name: 'scenicAreasDetail',
      hidden: true,
      component: () => import('@/views/commodityManagement/scenicAreas/detail'),
      meta: {
        title: '景点门票详情',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/commodityManagement/scenicAreas',
            breadcrumbName: '景点门票',
          },
        ],
      },
    },
    {
      path: '/commodityManagement/scenicAreasPageSetDetail',
      name: 'scenicAreasPageSetDetail',
      component: () => import('@/views/commodityManagement/scenicAreas/scenicAreasPageSetDetail'),
      hidden: true,
      meta: {
        title: '景点门票页面设置',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/commodityManagement/scenicAreas',
            breadcrumbName: '景点门票',
          },
        ],
      },
    },
    {
      path: '/commodityManagement/scenicAreasTicket',
      name: 'scenicAreasTicket',
      component: () => import('@/views/commodityManagement/scenicAreas/ticket'),
      hidden: true,
      meta: {
        title: '门票管理',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/commodityManagement/scenicAreas',
            breadcrumbName: '景点门票',
          },
        ],
      },
    },
    {
      path: '/commodityManagement/scenicAreasTicketDetail',
      name: 'scenicAreasTicketDetail',
      component: () => import('@/views/commodityManagement/scenicAreas/ticketDetail'),
      hidden: true,
      meta: {
        title: '门票管理详情',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/commodityManagement/scenicAreas',
            breadcrumbName: '景点门票',
          },
          {
            path: '/commodityManagement/scenicAreasTicket',
            breadcrumbName: '门票管理',
          },
        ],
      },
    },
    {
      path: '/commodityManagement/calendar',
      name: 'calendar',
      hidden: true,
      component: () => import('@/views/commodityManagement/scenicAreas/calendar'),
      meta: {
        title: '日历价格详情',
        keepAlive: false,
        permission: ['dashboard'],
      },
    },
    {
      path: '/commodityManagement/panelCalendar',
      name: 'panelCalendar',
      hidden: true,
      component: () => import('@/views/commodityManagement/package/panelCalendar'),
      meta: {
        title: '日历价格详情',
        keepAlive: false,
        permission: ['dashboard'],
      },
    },
    {
      path: '/commodityManagement/hotelHomestayDetail',
      name: 'hotelHomestayDetail',
      hidden: true,
      component: () => import('@/views/commodityManagement/hotelHomestay/detail'),
      meta: {
        title: '酒店民宿详情',
        subTitle: '民宿、农房',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/commodityManagement/hotelHomestay',
            breadcrumbName: '酒店民宿',
          },
        ],
      },
    },
    // {
    //   path: '/commodityManagement/roomManagementList',
    //   name: 'roomManagementList',
    // },
    {
      path: '/commodityManagement/hotelHomestayManageDetail',
      name: 'hotelHomestayManageDetail',
      hidden: true,
      component: () => import('@/views/commodityManagement/hotelHomestayManage/detail'),
      meta: {
        title: '酒店民宿管理详情',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/commodityManagement/hotelHomestay',
            breadcrumbName: '酒店民宿',
          },
          {
            path: '/commodityManagement/hotelHomestayManage',
            breadcrumbName: '酒店民宿管理',
          },
        ],
      },
    },
    {
      path: '/commodityManagement/roomManageDetail',
      name: 'roomManageDetail',
      hidden: true,
      component: () => import('@/views/commodityManagement/roomManage/detail'),
      meta: {
        title: '房型管理详情',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/commodityManagement/hotelHomestay',
            breadcrumbName: '酒店民宿',
          },
          {
            path: '/commodityManagement/hotelHomestayManage',
            breadcrumbName: '酒店民宿管理',
          },
          {
            path: '/commodityManagement/roomManage',
            breadcrumbName: '房型管理',
          },
        ],
      },
    },
    {
      path: '/commodityManagement/packageDetail',
      name: 'packageDetail',
      hidden: true,
      component: () => import('@/views/commodityManagement/package/detail'),
      meta: {
        title: '组合套餐详情',
        subTitle: '酒店、景点',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/commodityManagement/package',
            breadcrumbName: '组合套餐',
          },
        ],
      },
    },
    // {
    //   path: '/commodityManagement/sessionManageDetail',
    //   name: 'sessionManageDetail',
    //   hidden: true,
    //   component: () => import('@/views/commodityManagement/sessionManage/detail'),
    //   meta: {
    //     title: '场次管理详情',
    //     keepAlive: false,
    //     permission: ['dashboard']
    //   }
    // }
  ]
}

// 营销中心详情页面
let bSubject = function () {
  return [
    ...publicRouter(),
    {
      path: '/marketingCenter/aBulkDetail',
      name: 'aBulkDetail',
      component: () => import('@/views/marketingCenter/aBulk/detail'),
      hidden: true,
      meta: {
        title: '拼团详情',
        keepAlive: false,
        permission: ['dashboard'],
      },
    },
    {
      path: '/marketingCenter/balanceDetail',
      name: 'balanceDetail',
      component: () => import('@/views/marketingCenter/balance/detail'),
      hidden: true,
      meta: {
        title: '余额详情',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/marketingCenter/balance',
            breadcrumbName: '余额',
          },
        ],
      },
    },
    {
      path: '/marketingCenter/freightCouponDetail',
      name: 'freightCouponDetail',
      component: () => import('@/views/marketingCenter/freightCoupon/detail'),
      hidden: true,
      meta: {
        title: '运费详情',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/marketingCenter/freightCoupon',
            breadcrumbName: '运费',
          },
        ],
      },
    },
    {
      path: '/marketingCenter/indexBannerDetail',
      name: 'indexBannerDetail',
      hidden: true,
      component: () => import('@/views/marketingCenter/projectPage/indexBannerDetail'),
      meta: {
        title: '专题页-Banner管理详情',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/marketingCenter/projectPage',
            breadcrumbName: '专题页',
          },
        ],
      },
    },

    {
      path: '/marketingCenter/weChatMoneyDetail',
      name: 'weChatMoneyDetail',
      hidden: true,
      component: () => import('@/views/marketingCenter/weChatMoney/detail'),
      meta: {
        title: '微信红包详情',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/marketingCenter/weChatMoney',
            breadcrumbName: '微信红包',
          },
        ],
      },
    },
    {
      path: '/marketingCenter/couponsDetail',
      name: 'couponsDetail',
      hidden: true,
      component: () => import('@/views/marketingCenter/coupons/detail'),
      meta: {
        title: '优惠券详情',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/marketingCenter/coupons',
            breadcrumbName: '优惠券',
          },
        ],
      },
    },
    {
      path: '/marketingCenter/physicalCouponDetail',
      hidden: true,
      name: 'physicalCouponDetail',
      component: () => import('@/views/marketingCenter/physicalCoupon/detail'),
      meta: {
        title: '实物券详情',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/marketingCenter/physicalCoupon',
            breadcrumbName: '实物券',
          },
        ],
      },
    },
    {
      path: '/marketingCenter/bigWheelDetail',
      name: 'bigWheelDetail',
      hidden: true,
      component: () => import('@/views/marketingCenter/bigWheel/detail'),
      meta: {
        title: '大转盘详情',
        keepAlive: false,
        permission: ['dashboard'],
        breadRouters: [
          {
            path: '/marketingCenter/bigWheel',
            breadcrumbName: '大转盘详情',
          },
        ],
      },
    },
    {
      path: '/marketingCenter/detailActivity',
      name: 'detailActivity',
      hidden: true,
      component: () => import('@/views/marketingCenter/bannerActivity/detailActivity'),
      meta: {
        title: '活动详情',
        keepAlive: false,
        permission: ['dashboard'],
      },
    },
  ]
}

// 公共信息详情
let cSubmit = function () {
  return [
    {
      path: '/publicInfo/publicToiletDetail',
      name: 'publicToiletDetail',
      component: () => import('@/views/publicInfo/publicToilet/detail'),
      hidden: true,
      meta: {
        title: '公共厕所详情',
        keepAlive: false,
        permission: ['dashboard'],
      },
    },
    {
      path: '/publicInfo/parkingLotDetail',
      name: 'parkingLotDetail',
      component: () => import('@/views/publicInfo/parkingLot/detail'),
      hidden: true,
      meta: {
        title: '停车场详情',
        keepAlive: false,
        permission: ['dashboard'],
      },
    },
    {
      path: '/publicInfo/hospitalDetail',
      name: 'hospitalDetail',
      component: () => import('@/views/publicInfo/hospital/detail'),
      hidden: true,
      meta: {
        title: '医院详情',
        keepAlive: false,
        permission: ['dashboard'],
      },
    },
    {
      path: '/publicInfo/busStationDetail',
      name: 'busStationDetail',
      component: () => import('@/views/publicInfo/busStation/detail'),
      hidden: true,
      meta: {
        title: '公交站详情',
        keepAlive: false,
        permission: ['dashboard'],
      },
    },
  ]
}

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: {
      title: '首页',
    },
    redirect: '/dataKanban/kanban',
    children: [
      {
        path: '/dataKanban/kanban',
        name: 'kanban',
        component: () => import('@/views/dataKanban/kanban'),
        meta: {
          title: '数据看板',
          keepAlive: false,
          icon: order,
          permission: ['dashboard'],
        },
      },
      {
        path: '/commodityManagement/scenicAreas',
        name: 'scenicAreas',
        component: () => import('@/views/commodityManagement/scenicAreas'),
        meta: {
          title: '景点管理',
          keepAlive: false,
          icon: goods,
          permission: ['dashboard'],
        },
      },
      {
        path: '/commodityManagement/entityInformation',
        name: 'entityInformation',
        component: () => import('@/views/commodityManagement/entityInformation'),
        meta: {
          title: '商家管理',
          keepAlive: false,
          icon: goods,
          permission: ['dashboard'],
        },
      },
      {
        path: '/commodityManagement/hotelHomestay',
        name: 'hotelHomestay',
        component: () => import('@/views/commodityManagement/hotelHomestay'),
        meta: {
          title: '住宿管理',
          keepAlive: false,
          icon: goods,
          permission: ['dashboard'],
        },
      },
      {
        path: '/commodityManagement/specialty',
        name: 'specialty',
        component: () => import('@/views/commodityManagement/specialty'),
        meta: {
          title: '商品管理',
          keepAlive: false,
          icon: goods,
          permission: ['dashboard'],
        },
      },
      {
        path: '/audit/feedback',
        name: 'feedback',
        component: () => import('@/views/audit/feedback'),
        meta: {
          title: '反馈管理',
          keepAlive: false,
          permission: ['dashboard'],
          icon: goods,
        },
      },
      {
        path: '/contentManagement',
        name: 'contentManagement',
        redirect: '/platform/investment',
        component: RouteView,
        meta: {
          title: '内容管理',
          keepAlive: false,
          icon: jibenxinxi,
          permission: ['dashboard'],
        },
        children: [
          {
            path: '/publicInfo/activitys',
            name: 'activitys',
            component: () => import('@/views/publicInfo/activitys'),
            meta: {
              title: '活动管理',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/publicInfo/activitysDetail',
            name: 'activitysDetail',
            component: () => import('@/views/publicInfo/activitys/detail'),
            hidden: true,
            meta: {
              title: '活动详情',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/publicInfo/activitys',
                  breadcrumbName: '活动',
                },
              ],
            },
          },
          {
            path: '/contentManagement/studyFurther',
            name: 'studyFurther',
            component: () => import('@/views/contentManagement/studyFurther'),
            meta: {
              title: '研学管理',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/contentManagement/studyFurtherDetail',
            name: 'studyFurtherDetail',
            component: () => import('@/views/contentManagement/studyFurther/detail'),
            hidden: true,
            meta: {
              title: '研学管理详情',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/platform/baseInfo',
            name: 'baseInfo',
            component: () => import('@/views/platform/baseInfo'),
            meta: {
              title: '金刚区管理',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/scenicSpotGuide/index',
            name: 'scenicSpotGuide',
            component: () => import('@/views/scenicSpotGuide'),
            meta: {
              title: '景区导览',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/contentManagement/applicationForm',
            name: 'applicationForm',
            component: () => import('@/views/contentManagement/applicationForm'),
            hidden: true,
            meta: {
              title: '报名单',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/bannerActivity',
            name: 'bannerActivity',
            component: () => import('@/views/marketingCenter/bannerActivity'),
            meta: {
              title: 'Banner',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/detailBanner',
            name: 'detailBanner',
            hidden: true,
            component: () => import('@/views/marketingCenter/bannerActivity/detailBanner'),
            meta: {
              title: 'Banner详情',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/bannerActivity',
                  breadcrumbName: 'Banner',
                },
              ],
            },
          },
          {
            path: '/contentManagement/yunXian',
            name: 'yunXian',
            component: () => import('@/views/contentManagement/yunXian'),
            meta: {
              title: '云贤',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/contentManagement/yunXianDetail',
            name: 'yunXianDetail',
            component: () => import('@/views/contentManagement/yunXian/detail'),
            hidden: true,
            meta: {
              title: '云贤详情',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/contentManagement/parkingLot',
            name: 'parkingLot',
            component: () => import('@/views/contentManagement/parkingLot'),
            meta: {
              title: '停车管理',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/contentManagement/parkingLotDetail',
            name: 'parkingLotDetail',
            component: () => import('@/views/contentManagement/parkingLot/detail'),
            hidden: true,
            meta: {
              title: '停车场管理详情',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          // {
          //   path: '/contentManagement/parkingGuide',
          //   name: 'parkingGuide',
          //   component: () => import('@/views/contentManagement/parkingGuide'),
          //   meta: {
          //     title: '停车导览',
          //     keepAlive: false,
          //     permission: ['dashboard'],
          //   },
          // },
          {
            path: '/marketingCenter/farmersBrigade',
            name: 'farmersBrigade',
            component: () => import('@/views/marketingCenter/farmersBrigade'),
            meta: {
              title: '旅游线路',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/farmersBrigadeDetail',
            name: 'farmersBrigadeDetail',
            hidden: true,
            component: () => import('@/views/marketingCenter/farmersBrigade/detail'),
            meta: {
              title: '旅游线路详情',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/farmersBrigade',
                  breadcrumbName: '旅游线路',
                },
              ],
            },
          },
          {
            path: '/scenicSpotGuide/publicFacilities',
            name: 'scenicSpotGuidePublicFacilities',
            hidden: true,
            component: () => import('@/views/scenicSpotGuide/publicFacilities'),
            meta: {
              title: '地址管理(公共设施)',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/scenicSpotGuide/studyFurther',
            name: 'scenicSpotGuideStudyFurther',
            hidden: true,
            component: () => import('@/views/scenicSpotGuide/studyFurther'),
            meta: {
              title: '地址管理(研学)',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/scenicSpotGuide/scenicSpot',
            name: 'scenicSpotGuidescenicSpot',
            hidden: true,
            component: () => import('@/views/scenicSpotGuide/scenicSpot'),
            meta: {
              title: '地址管理(景点)',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/scenicSpotGuide/groggery',
            name: 'scenicSpotGuideGroggery',
            hidden: true,
            component: () => import('@/views/scenicSpotGuide/groggery'),
            meta: {
              title: '地址管理(酒店)',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/scenicSpotGuide/shop',
            name: 'scenicSpotGuideShop',
            hidden: true,
            component: () => import('@/views/scenicSpotGuide/shop'),
            meta: {
              title: '地址管理(店铺)',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/projectPage',
            name: 'projectPage',
            component: () => import('@/views/marketingCenter/projectPage'),
            meta: {
              title: '专题页',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/indexTemplate',
            name: 'indexTemplate',
            hidden: true,
            component: () => import('@/views/marketingCenter/projectPage/indexTemplate'),
            meta: {
              title: '专题页-模板管理',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/projectPage',
                  breadcrumbName: '专题页',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/projectPage/detail',
            name: 'projectPageDetail',
            hidden: true,
            component: () => import('@/views/marketingCenter/projectPage/detail'),
            meta: {
              title: '专题页详情',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/indexBanner',
            name: 'indexBanner',
            hidden: true,
            component: () => import('@/views/marketingCenter/projectPage/indexBanner'),
            meta: {
              title: '专题页-Banner管理',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/projectPage',
                  breadcrumbName: '专题页',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/projectPageType',
            name: 'projectPageType',
            hidden: true,
            component: () => import('@/views/marketingCenter/projectPage/indexType'),
            meta: {
              title: '专题页-分类',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/projectPage',
                  breadcrumbName: '专题页',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/projectPageGoods',
            name: 'projectPageGoods',
            hidden: true,
            component: () => import('@/views/marketingCenter/projectPage/indexGoods'),
            meta: {
              title: '专题页-商品',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/projectPage',
                  breadcrumbName: '专题页',
                },
              ],
            },
          },
          {
            path: '/platform/investment',
            name: 'investment',
            component: () => import('@/views/platform/investment'),
            meta: {
              title: '新闻公告',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/platform/investmentDetail',
            name: 'investmentDetail',
            hidden: true,
            component: () => import('@/views/platform/investment/detail'),
            meta: {
              title: '新闻公告详情',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/platform/investment',
                  breadcrumbName: '新闻公告',
                },
              ],
            },
          },
          {
            path: '/contentManagement/ruralVideo',
            name: 'ruralVideo',
            component: () => import('@/views/contentManagement/ruralVideo'),
            meta: {
              title: '乡村视频',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/contentManagement/ruralVideoDetail',
            name: 'ruralVideo',
            component: () => import('@/views/contentManagement/ruralVideo/detail'),
            hidden: true,
            meta: {
              title: '乡村视频详情',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
        ],
      },

      // 下面不符合大堰镇验收
      {
        path: '/dataKanban',
        name: 'dataKanban',
        redirect: '/dataKanban/kanban',
        component: RouteView,

        hidden: true,

        meta: {
          title: '数据看板',
          keepAlive: false,
          icon: order,
          permission: ['dashboard'],
        },
        children: [
          {
            path: '/dataKanban/kanban',
            name: 'kanban',
            component: () => import('@/views/dataKanban/kanban'),
            meta: {
              // title: '看板',
              title: '概况',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/report/scenicSpotSignage',
            name: 'scenicSpotSignage',
            component: () => import('@/views/report/scenicSpotSignage.vue'),
            meta: {
              title: '景点看板',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
        ],
      },
      {
        path: '/dataReport',
        name: '/dataReport',
        component: RouteView,

        hidden: true,

        meta: {
          title: '数据报表',
        },
        children: dataReport(),
      },
      {
        path: '/orderProcessing',
        name: 'orderProcessing',
        redirect: '/orderProcessing/localProducts',
        component: RouteView,

        hidden: true,

        meta: {
          title: '订单处理',
          keepAlive: false,
          icon: order,
          permission: ['dashboard'],
        },
        children: [
          {
            path: '/orderProcessing/localProducts',
            name: 'localProducts',
            component: () => import('@/views/orderProcessing/localProducts'),
            meta: {
              title: '特产商品',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/orderProcessing/facility',
            name: 'facility',
            component: () => import('@/views/orderProcessing/facility'),
            meta: {
              title: '民宿农舍',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/orderProcessing/hotel',
            name: 'hotel',
            component: () => import('@/views/orderProcessing/hotel'),
            meta: {
              title: '酒店民宿',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/orderProcessing/eatDrink',
            name: 'eatDrink',
            component: () => import('@/views/orderProcessing/eatDrink'),
            meta: {
              title: '农家乐',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/orderProcessing/farmingExperience',
            name: 'farmingExperience',
            component: () => import('@/views/orderProcessing/farmingExperience'),
            meta: {
              title: '农事体验',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/orderProcessing/scenicAreasTickets',
            name: 'scenicAreasTickets',
            component: () => import('@/views/orderProcessing/scenicAreasTickets'),
            meta: {
              title: '景点门票',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/orderProcessing/package',
            name: 'packageOrder',
            component: () => import('@/views/orderProcessing/package'),
            meta: {
              title: '组合套餐',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/orderProcessing/offlinePay',
            name: 'offlinePay',
            component: () => import('@/views/orderProcessing/offlinePay'),
            meta: {
              title: '线下支付',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/orderProcessing/refund',
            name: 'refund',
            component: () => import('@/views/orderProcessing/refund'),
            meta: {
              title: '退款处理',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/orderProcessing/verification',
            name: 'verification',
            component: () => import('@/views/orderProcessing/verification'),
            meta: {
              title: '商品核销',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/orderProcessing/ship',
            name: 'ship',
            component: () => import('@/views/orderProcessing/ship'),
            meta: {
              title: '船票管理',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/orderProcessing/car',
            name: 'car',
            component: () => import('@/views/orderProcessing/car'),
            meta: {
              title: '车票管理',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/orderProcessing/settlement',
            name: 'settlement',
            hidden: true,
            component: () => import('@/views/orderProcessing/settlement'),
            meta: {
              title: '结算中心',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
        ],
      },
      {
        path: '/commodityManagement',
        name: 'commodityManagement',
        redirect: '/commodityManagement/entityInformation',
        component: RouteView,

        hidden: true,

        meta: {
          title: '商品管理',
          keepAlive: false,
          icon: goods,
          permission: ['dashboard'],
        },
        children: [
          ...aSubject(),
          {
            path: '/commodityManagement/entityInformation',
            name: 'entityInformation',
            component: () => import('@/views/commodityManagement/entityInformation'),
            meta: {
              title: '主体信息',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },

          {
            path: '/commodityManagement/specialty',
            name: 'specialty',
            component: () => import('@/views/commodityManagement/specialty'),
            meta: {
              title: '农家特产',
              keepAlive: false,
              subTitle: '商城、商品',
              permission: ['dashboard'],
            },
          },

          {
            path: '/commodityManagement/eat',
            name: 'eat',
            component: () => import('@/views/commodityManagement/eat'),
            meta: {
              title: '农家乐',
              keepAlive: false,
              subTitle: '美食、套餐',
              permission: ['dashboard'],
            },
          },

          {
            path: '/commodityManagement/live',
            name: 'live',
            component: () => import('@/views/commodityManagement/live'),
            meta: {
              title: '民宿农舍',
              keepAlive: false,
              subTitle: '民宿、农房',
              permission: ['dashboard'],
            },
          },

          {
            path: '/commodityManagement/farming',
            name: 'farming',
            component: () => import('@/views/commodityManagement/farming'),
            meta: {
              title: '农事体验',
              keepAlive: false,
              subTitle: '采摘、垂钓',
              permission: ['dashboard'],
            },
          },
          {
            path: '/commodityManagement/scenicAreas',
            name: 'scenicAreas',
            component: () => import('@/views/commodityManagement/scenicAreas'),
            meta: {
              title: '景点门票',
              keepAlive: false,
              subTitle: '景区、农场',
              permission: ['dashboard'],
            },
          },
          {
            path: '/commodityManagement/hotelHomestay',
            name: 'hotelHomestay',
            component: () => import('@/views/commodityManagement/hotelHomestay'),
            meta: {
              title: '酒店民宿',
              subTitle: '民宿、农房',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/commodityManagement/package',
            name: 'package',
            component: () => import('@/views/commodityManagement/package'),
            meta: {
              title: '组合套餐',
              subTitle: '酒店、景点',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/commodityManagement/hotelHomestayManage',
            name: 'hotelHomestayManage',
            hidden: true,
            component: () => import('@/views/commodityManagement/hotelHomestayManage'),
            meta: {
              title: '酒店民宿管理',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/commodityManagement/hotelHomestay',
                  breadcrumbName: '酒店民宿',
                },
              ],
            },
          },
          {
            path: '/commodityManagement/roomManage',
            name: 'roomManage',
            hidden: true,
            component: () => import('@/views/commodityManagement/roomManage'),
            meta: {
              title: '房型管理',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/commodityManagement/hotelHomestay',
                  breadcrumbName: '酒店民宿',
                },
                {
                  path: '/commodityManagement/hotelHomestayManage',
                  breadcrumbName: '酒店民宿管理',
                },
              ],
            },
          },
          // {
          //   path: '/commodityManagement/sessionManage',
          //   name: 'sessionManage',
          //   component: () => import('@/views/commodityManagement/sessionManage'),
          //   meta: {
          //     title: '场次管理',
          //     keepAlive: false,
          //     permission: ['dashboard']
          //   }
          // }
          {
            path: '/commodityManagement/ticketSaleGate',
            name: 'ticketSaleGate',
            component: () => import('@/views/commodityManagement/ticketSaleGate'),
            meta: {
              title: '售检票口',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/commodityManagement/ticketSaleGateDetail',
            name: 'ticketSaleGateDetail',
            component: () => import('@/views/commodityManagement/ticketSaleGate/detail'),
            hidden: true,
            meta: {
              title: '售检票口详情',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/commodityManagement/gateMachineManage',
            name: 'gateMachineManage',
            component: () => import('@/views/commodityManagement/gateMachineManage'),
            meta: {
              title: '闸机管理',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/commodityManagement/gateMachineManageDetail',
            name: 'gateMachineManageDetail',
            component: () => import('@/views/commodityManagement/gateMachineManage/detail'),
            hidden: true,
            meta: {
              title: '闸机详情',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/commodityManagement/gateMachineManage',
                  breadcrumbName: '闸机管理',
                },
              ],
            },
          },
          {
            path: '/commodityManagement/invoicesManage',
            name: 'invoicesManage',
            component: () => import('@/views/commodityManagement/invoicesManage'),
            meta: {
              title: '发票管理',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
        ],
      },
      {
        path: '/marketingCenter',
        name: 'marketingCenter',
        redirect: '/marketingCenter/coupons',
        component: RouteView,

        hidden: true,

        meta: {
          title: '营销中心',
          keepAlive: false,
          icon: marketing,
          permission: ['dashboard'],
        },
        children: [
          ...bSubject(),
          {
            path: '/marketingCenter/distributionManage',
            name: 'distributionManage',
            component: () => import('@/views/marketingCenter/distributionManage'),
            meta: {
              title: '分销管理',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/distributionSet',
            name: 'distributionSet',
            hidden: true,
            component: () => import('@/views/marketingCenter/distributionSet'),
            meta: {
              title: '分销设置',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/distributionManage',
                  breadcrumbName: '分销管理',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/timedDraw',
            name: 'timedDraw',
            component: () => import('@/views/marketingCenter/timedDraw'),
            meta: {
              title: '定时抽奖',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/timedDrawDetail',
            name: 'timedDrawDetail',
            component: () => import('@/views/marketingCenter/timedDraw/detail'),
            hidden: true,
            meta: {
              title: '定时抽奖详情',
              keepAlive: false,
              permission: ['dashboard'],
            },
            breadRouters: [
              {
                path: '/marketingCenter/timedDraw',
                breadcrumbName: '定时抽奖',
              },
            ],
          },
          {
            path: '/marketingCenter/indexWinPrize',
            name: 'indexWinPrize',
            component: () => import('@/views/marketingCenter/timedDraw/indexWinPrize'),
            hidden: true,
            meta: {
              title: '中奖情况',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/timedDraw',
                  breadcrumbName: '定时抽奖',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/aBulk',
            name: 'aBulk',
            component: () => import('@/views/marketingCenter/aBulk'),
            meta: {
              title: '拼团',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/bulkDetail',
            name: 'bulkDetail',
            component: () => import('@/views/marketingCenter/aBulk/indexBulk'),
            hidden: true,
            meta: {
              title: '拼团详情',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/aBulk',
                  breadcrumbName: '拼团',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/balance',
            name: 'balance',
            component: () => import('@/views/marketingCenter/balance'),
            meta: {
              title: '余额',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/indexCard',
            name: 'indexCard',
            component: () => import('@/views/marketingCenter/balance/indexCard'),
            hidden: true,
            meta: {
              title: '余额卡用户管理',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/balance',
                  breadcrumbName: '余额',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/indexVoucher',
            name: 'indexVoucher',
            component: () => import('@/views/marketingCenter/balance/indexVoucher'),
            hidden: true,
            meta: {
              title: '余额券用户管理',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/balance',
                  breadcrumbName: '余额',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/freightCoupon',
            name: 'freightCoupon',
            component: () => import('@/views/marketingCenter/freightCoupon'),
            meta: {
              title: '运费',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/freightCouponUser',
            name: 'freightCouponUser',
            component: () => import('@/views/marketingCenter/freightCoupon/freightCouponUser'),
            hidden: true,
            meta: {
              title: '运费券用户管理',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/freightCoupon',
                  breadcrumbName: '运费',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/projectPage',
            name: 'projectPage',
            component: () => import('@/views/marketingCenter/projectPage'),
            meta: {
              title: '专题页',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/indexTemplate',
            name: 'indexTemplate',
            hidden: true,
            component: () => import('@/views/marketingCenter/projectPage/indexTemplate'),
            meta: {
              title: '专题页-模板管理',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/projectPage',
                  breadcrumbName: '专题页',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/projectPage/detail',
            name: 'projectPageDetail',
            hidden: true,
            component: () => import('@/views/marketingCenter/projectPage/detail'),
            meta: {
              title: '专题页详情',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/indexBanner',
            name: 'indexBanner',
            hidden: true,
            component: () => import('@/views/marketingCenter/projectPage/indexBanner'),
            meta: {
              title: '专题页-Banner管理',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/projectPage',
                  breadcrumbName: '专题页',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/projectPageType',
            name: 'projectPageType',
            hidden: true,
            component: () => import('@/views/marketingCenter/projectPage/indexType'),
            meta: {
              title: '专题页-分类',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/projectPage',
                  breadcrumbName: '专题页',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/projectPageGoods',
            name: 'projectPageGoods',
            hidden: true,
            component: () => import('@/views/marketingCenter/projectPage/indexGoods'),
            meta: {
              title: '专题页-商品',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/projectPage',
                  breadcrumbName: '专题页',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/weChatMoney',
            name: 'weChatMoney',
            component: () => import('@/views/marketingCenter/weChatMoney'),
            meta: {
              title: '微信红包',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/weChatMoneyReceive',
            name: 'weChatMoneyReceive',
            hidden: true,
            component: () => import('@/views/marketingCenter/weChatMoney/weChatReceive'),
            meta: {
              title: '微信红包领取',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/weChatMoney',
                  breadcrumbName: '微信红包',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/coupons',
            name: 'coupons',
            component: () => import('@/views/marketingCenter/coupons'),
            meta: {
              title: '优惠券',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/couponsReceive',
            name: 'couponsReceive',
            hidden: true,
            component: () => import('@/views/marketingCenter/coupons/couponsReceive'),
            meta: {
              title: '优惠券领取',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/coupons',
                  breadcrumbName: '优惠券',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/physicalCoupon',
            name: 'physicalCoupon',
            component: () => import('@/views/marketingCenter/physicalCoupon'),
            meta: {
              title: '实物券',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/physicalCouponReceive',
            name: 'physicalCouponReceive',
            hidden: true,
            component: () => import('@/views/marketingCenter/physicalCoupon/physicalCouponReceive'),
            meta: {
              title: '实物券领取',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/physicalCoupon',
                  breadcrumbName: '实物券',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/secondsKill',
            name: 'secondsKill',
            component: () => import('@/views/marketingCenter/secondsKill'),
            meta: {
              title: '秒杀专区',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/liveList',
            name: 'liveList',
            component: () => import('@/views/marketingCenter/liveList'),
            meta: {
              title: '直播列表',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/bigWheel',
            name: 'bigWheel',
            component: () => import('@/views/marketingCenter/bigWheel'),
            meta: {
              title: '大转盘',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/bigWheelDetail/bigTurntable',
            name: 'bigTurntable',
            hidden: true,
            component: () => import('@/views/marketingCenter/bigWheel/bigTurntable/bigdetail'),
            meta: {
              title: '奖品',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/bigWheel',
                  breadcrumbName: '大转盘',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/bigWheelDetail/bigthanks',
            name: 'bigthanks',
            hidden: true,
            component: () => import('@/views/marketingCenter/bigWheel/bigTurntable/bigthanks'),
            meta: {
              title: '谢谢惠顾中奖次数',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/bigWheel',
                  breadcrumbName: '大转盘',
                },
              ],
            },
          },

          {
            path: '/marketingCenter/secondary',
            name: 'secondary',
            component: () => import('@/views/marketingCenter/secondary'),
            meta: {
              title: '二级商户',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/secondaryDetail',
            name: 'secondaryDetail',
            hidden: true,
            component: () => import('@/views/marketingCenter/secondary/detail'),
            meta: {
              title: '二级商户详情',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/secondary',
                  breadcrumbName: '二级商户',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/supplementDetail',
            name: 'supplementDetail',
            hidden: true,
            component: () => import('@/views/marketingCenter/secondary/supplementDetail'),
            meta: {
              title: '二级商户补录详情',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/secondary',
                  breadcrumbName: '二级商户',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/appPush',
            name: 'appPush',
            component: () => import('@/views/marketingCenter/appPush'),
            meta: {
              title: '群体推送',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
        ],
      },
      {
        path: '/contentManagement',
        name: 'contentManagement',
        redirect: '/platform/investment',
        component: RouteView,

        hidden: true,

        meta: {
          title: '内容管理',
          keepAlive: false,
          icon: jibenxinxi,
          permission: ['dashboard'],
        },
        children: [
          {
            path: '/seeding/index',
            name: 'seeding',
            component: () => import('@/views/seeding'),
            meta: {
              title: '种草社区',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/seeding/add',
            name: 'seedingAdd',
            component: () => import('@/views/seeding/add.vue'),
            hidden: true,
            meta: {
              title: '种草社区详情',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/scenicSpotGuide/index',
            name: 'scenicSpotGuide',
            component: () => import('@/views/scenicSpotGuide'),
            meta: {
              title: '景区导览',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/scenicSpotGuide/publicFacilities',
            name: 'scenicSpotGuidePublicFacilities',
            hidden: true,
            component: () => import('@/views/scenicSpotGuide/publicFacilities'),
            meta: {
              title: '地址管理(公共设施)',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/scenicSpotGuide/studyFurther',
            name: 'scenicSpotGuideStudyFurther',
            hidden: true,
            component: () => import('@/views/scenicSpotGuide/studyFurther'),
            meta: {
              title: '地址管理(研学)',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/scenicSpotGuide/scenicSpot',
            name: 'scenicSpotGuidescenicSpot',
            hidden: true,
            component: () => import('@/views/scenicSpotGuide/scenicSpot'),
            meta: {
              title: '地址管理(景点)',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/scenicSpotGuide/groggery',
            name: 'scenicSpotGuideGroggery',
            hidden: true,
            component: () => import('@/views/scenicSpotGuide/groggery'),
            meta: {
              title: '地址管理(酒店)',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/scenicSpotGuide/shop',
            name: 'scenicSpotGuideShop',
            hidden: true,
            component: () => import('@/views/scenicSpotGuide/shop'),
            meta: {
              title: '地址管理(店铺)',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/platform/investment',
            name: 'investment',
            component: () => import('@/views/platform/investment'),
            meta: {
              title: '新闻公告',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/platform/investmentDetail',
            name: 'investmentDetail',
            hidden: true,
            component: () => import('@/views/platform/investment/detail'),
            meta: {
              title: '新闻公告详情',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/platform/investment',
                  breadcrumbName: '新闻公告',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/rural',
            name: 'rural',
            component: () => import('@/views/marketingCenter/rural'),
            meta: {
              title: '乡村印象',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/ruralDetail',
            name: 'ruralDetail',
            hidden: true,
            component: () => import('@/views/marketingCenter/rural/detail'),
            meta: {
              title: '乡村印象详情',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/ruralDetail',
                  breadcrumbName: '乡村印象',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/travel',
            name: 'travel',
            component: () => import('@/views/marketingCenter/travel'),
            meta: {
              title: '旅行月历',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/travelDetail',
            name: 'travelDetail',
            hidden: true,
            component: () => import('@/views/marketingCenter/travel/detail'),
            meta: {
              title: '旅行月历详情',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/travel',
                  breadcrumbName: '旅行月历',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/farmersBrigade',
            name: 'farmersBrigade',
            component: () => import('@/views/marketingCenter/farmersBrigade'),
            meta: {
              title: '旅行攻略',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/farmersBrigadeDetail',
            name: 'farmersBrigadeDetail',
            hidden: true,
            component: () => import('@/views/marketingCenter/farmersBrigade/detail'),
            meta: {
              title: '旅行攻略详情',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/farmersBrigade',
                  breadcrumbName: '旅行攻略',
                },
              ],
            },
          },
          {
            path: '/publicInfo/activitys',
            name: 'activitys',
            component: () => import('@/views/publicInfo/activitys'),
            meta: {
              title: '活动',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/publicInfo/activitysDetail',
            name: 'activitysDetail',
            component: () => import('@/views/publicInfo/activitys/detail'),
            hidden: true,
            meta: {
              title: '活动详情',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/publicInfo/activitys',
                  breadcrumbName: '活动',
                },
              ],
            },
          },
          {
            path: '/marketingCenter/bannerActivity',
            name: 'bannerActivity',
            component: () => import('@/views/marketingCenter/bannerActivity'),
            meta: {
              title: 'Banner',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/marketingCenter/detailBanner',
            name: 'detailBanner',
            hidden: true,
            component: () => import('@/views/marketingCenter/bannerActivity/detailBanner'),
            meta: {
              title: 'Banner详情',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/marketingCenter/bannerActivity',
                  breadcrumbName: 'Banner',
                },
              ],
            },
          },
          {
            path: '/contentManagement/FAQManagement',
            name: 'FAQManagement',
            component: () => import('@/views/contentManagement/FAQManagement'),
            meta: {
              title: 'FAQ管理',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/contentManagement/problemManagement',
            name: 'problemManagement',
            hidden: true,
            component: () => import('@/views/contentManagement/FAQManagement/problemManagement'),
            meta: {
              title: '问题管理',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/contentManagement/ruralVideo',
            name: 'ruralVideo',
            component: () => import('@/views/contentManagement/ruralVideo'),
            meta: {
              title: '乡村视频',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/contentManagement/ruralVideoDetail',
            name: 'ruralVideo',
            component: () => import('@/views/contentManagement/ruralVideo/detail'),
            hidden: true,
            meta: {
              title: '乡村视频详情',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/contentManagement/parkingLot',
            name: 'parkingLot',
            component: () => import('@/views/contentManagement/parkingLot'),
            meta: {
              title: '停车场管理',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/contentManagement/parkingLotDetail',
            name: 'parkingLotDetail',
            component: () => import('@/views/contentManagement/parkingLot/detail'),
            hidden: true,
            meta: {
              title: '停车场管理详情',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/contentManagement/parkingGuide',
            name: 'parkingGuide',
            component: () => import('@/views/contentManagement/parkingGuide'),
            meta: {
              title: '停车导览',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/contentManagement/studyFurther',
            name: 'studyFurther',
            component: () => import('@/views/contentManagement/studyFurther'),
            meta: {
              title: '研学管理',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/contentManagement/studyFurtherDetail',
            name: 'studyFurtherDetail',
            component: () => import('@/views/contentManagement/studyFurther/detail'),
            hidden: true,
            meta: {
              title: '研学管理详情',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/contentManagement/applicationForm',
            name: 'applicationForm',
            component: () => import('@/views/contentManagement/applicationForm'),
            hidden: true,
            meta: {
              title: '报名单',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
        ],
      },
      {
        path: '/audit',
        name: 'audit',
        redirect: '/audit/entityInformation',
        component: RouteView,

        hidden: true,

        meta: {
          title: '系统审核',
          keepAlive: false,
          icon: audit,
          permission: ['dashboard'],
        },
        children: [
          {
            path: '/audit/evaluate',
            name: 'evaluate',
            component: () => import('@/views/audit/evaluate'),
            meta: {
              title: '评价审核',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/audit/feedback',
            name: 'feedback',
            component: () => import('@/views/audit/feedback'),
            meta: {
              title: '意见反馈',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/audit/settlementAudit',
            name: 'settlementAudit',
            component: () => import('@/views/audit/settlementAudit'),
            meta: {
              title: '结算审核',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/audit/settlementAuditIndexDetail',
            name: 'settlementAuditIndexDetail',
            component: () => import('@/views/audit/settlementAudit/indexDetail'),
            meta: {
              title: '结算审核详情',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/audit/settlementAudit',
                  breadcrumbName: '结算审核',
                },
              ],
            },
            hidden: true,
          },
          {
            path: '/audit/mainBack',
            name: 'mainBack',
            component: () => import('@/views/audit/mainBack'),
            meta: {
              title: '主体追溯',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/audit/mainBackDetail',
            name: 'mainBackDetail',
            hidden: true,
            component: () => import('@/views/audit/mainBack/detail'),
            meta: {
              title: '主体追溯详情',
              keepAlive: false,
              permission: ['dashboard'],
              breadRouters: [
                {
                  path: '/audit/mainBack',
                  breadcrumbName: '主体追溯',
                },
              ],
            },
          },
        ],
      },
      {
        path: '/platform',
        name: 'platform',
        redirect: '/platform/baseInfo',
        component: RouteView,

        hidden: true,

        meta: {
          title: '平台设置',
          keepAlive: false,
          icon: configuration,
          permission: ['dashboard'],
        },
        children: [
          {
            path: '/platform/baseInfo',
            name: 'baseInfo',
            component: () => import('@/views/platform/baseInfo'),
            meta: {
              title: '基本信息',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/platform/labelManagement',
            name: 'labelManagement',
            component: () => import('@/views/platform/labelManagement'),
            meta: {
              title: '标签管理',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/platform/farmersBrigadeMap',
            name: 'farmersBrigadeMap',
            component: () => import('@/views/platform/farmersBrigadeMap'),
            meta: {
              title: '农旅地图',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/platform/pickUp',
            name: 'pickUp',
            component: () => import('@/views/platform/pickUp'),
            meta: {
              title: '农业局提货店',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/platform/freight',
            name: 'freight',
            component: () => import('@/views/platform/freight'),
            meta: {
              title: '运费模板',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/platform/freightDetail',
            name: 'freightDetail',
            hidden: true,
            component: () => import('@/views/platform/freight/detail'),
            meta: {
              title: '运费模板详情',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/platform/specialProduct',
            name: 'specialProduct',
            component: () => import('@/views/platform/specialProduct'),
            meta: {
              title: '特产分类',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/platform/appVersion',
            name: 'appVersion',
            component: () => import('@/views/platform/appVersion'),
            meta: {
              title: 'APP版本管理',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/platform/userManagement',
            name: 'userManagement',
            component: () => import('@/views/platform/userManagement'),
            meta: {
              title: '用户管理',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/platform/userManagementDetail',
            name: 'userManagementDetail',
            hidden: true,
            component: () => import('@/views/platform/userManagement/detail'),
            meta: {
              title: '用户信息',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
        ],
      },
      {
        path: '/system',
        name: 'system',
        redirect: '/system/clerkAccountNumber',
        component: RouteView,

        hidden: true,

        meta: {
          title: '系统设置',
          keepAlive: false,
          icon: configuration,
          permission: ['dashboard'],
        },
        children: [
          {
            path: '/system/clerkAccountNumber',
            name: 'clerkAccountNumber',
            component: () => import('@/views/system/clerkAccountNumber'),
            meta: {
              title: '店员账号',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/system/roleAuthority',
            name: 'roleAuthority',
            component: () => import('@/views/system/roleAuthority'),
            meta: {
              title: '角色权限',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
        ],
      },
      {
        path: '/publicInfo',
        name: 'publicInfo',
        redirect: '/publicInfo/publicToilet',
        component: RouteView,

        hidden: true,

        meta: {
          title: '公共信息',
          keepAlive: false,
          icon: configuration,
          permission: ['dashboard'],
        },
        children: [
          ...cSubmit(),
          {
            path: '/publicInfo/publicToilet',
            name: 'publicToilet',
            component: () => import('@/views/publicInfo/publicToilet'),
            meta: {
              title: '公共厕所',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/publicInfo/parkingLot',
            name: 'parkingLot',
            component: () => import('@/views/publicInfo/parkingLot'),
            meta: {
              title: '停车场',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/publicInfo/hospital',
            name: 'hospital',
            component: () => import('@/views/publicInfo/hospital'),
            meta: {
              title: '医院',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/publicInfo/busStation',
            name: 'busStation',
            component: () => import('@/views/publicInfo/busStation'),
            meta: {
              title: '公交站',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/publicInfo/toSignUp',
            hidden: true,
            name: 'toSignUp',
            component: () => import('@/views/publicInfo/activitys/toSignUp'),
            meta: {
              title: '报名查询',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/publicInfo/idle',
            name: 'idle',
            component: () => import('@/views/publicInfo/idle'),
            meta: {
              title: '闲置',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/publicInfo/employment',
            name: 'employment',
            component: () => import('@/views/publicInfo/employment'),
            meta: {
              title: '就业管理',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
          {
            path: '/publicInfo/training',
            name: 'training',
            component: () => import('@/views/publicInfo/training'),
            meta: {
              title: '培训管理',
              keepAlive: false,
              permission: ['dashboard'],
            },
          },
        ],
      },
    ],
  },
  {
    path: '*',
    redirect: '/404',
    name: '404',
    hidden: true,
  },
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  // {
  //   path: '/test',
  //   name: 'test',
  //   component: () => import('@/views/test')
  // },
  {
    path: '/user',
    component: RouteView,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: '/personalsettingDetail',
        name: 'personalsettingDetail',
        component: () => import(/* webpackChunkName: "user" */ '@/views/userinforsetting/personalsetting/detail'),
      },
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login'),
      },
      {
        path: 'loginDYZ',
        name: 'loginDYZ',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/LoginDYZ'),
      },
      {
        path: 'navDYZ',
        name: 'navDYZ',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/NavDYZ'),
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register'),
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult'),
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined,
      },
      {
        path: 'accredit',
        name: 'accredit',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/accredit'),
      },
    ],
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
  },
]
