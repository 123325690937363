<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  render() {
    return (
      <div class="form-imgs">
        {this.value &&
          this.value.split(',').map((e) => {
            return <img src={e} />
          })}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.form-imgs {
  img {
    width: 200px;
    height: 120px;
    border-radius: 4px;
    border: 1px dotted #ccc;
    margin: 0 10px 10px 0;
    object-fit: contain;
  }
}
</style>
