<script>
import FormView from '@/components/FormView'
import FormUpload from '@/components/FormView/FormUpload'
import { Input } from 'ant-design-vue'

export default {
  data() {
    return {
      form: {
        padding: '',
        radius: '',
        bkColor: 'white',
        data: [],
      },
    }
  },
  methods: {
    sumbit() {
      this.$refs.form.sumbit()
      // this.$emit('success', this.form)
    },
  },
  render() {
    return (
      <FormView
        ref="form"
        form={this.form}
        layout={'horizontal'}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onSuccess={(data) => this.$emit('success', data)}
        data={[
          {
            name: '外边距',
            type: 'inputNumber',
            key: 'margin',
            labelCol: 16,
            wrapperCol: 8,
          },
          {
            name: '内边距',
            type: 'inputNumber',
            key: 'padding',
            labelCol: 16,
            wrapperCol: 8,
          },
          {
            name: '圆角值',
            type: 'inputNumber',
            key: 'radius',
            labelCol: 16,
            wrapperCol: 8,
          },
          {
            name: '背景颜色',
            type: 'colorPicker',
            key: 'bkColor',
            labelCol: 16,
            wrapperCol: 8,
          },
          {
            name: '样式类型',
            type: 'radio',
            key: 'type',
            labelCol: 16,
            wrapperCol: 8,
            typeData: Array(8)
              .fill({})
              .map((e, i) => {
                return {
                  name: `样式${i + 1}`,
                  value: (i + 1).toString(),
                }
              }),
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '图片组',
            type: 'table',
            key: 'data',
            showFootButton: true,
            labelCol: 16,
            wrapperCol: 8,
            showPagination: false,
            dataSource: this.form.data,
            onAddData: () => {
              return { url: '', img: '' }
            },
            columns: [
              {
                title: '图片',
                dataIndex: 'img',
                align: 'center',
                customRender: function (text, records, index, h) {
                  return (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <FormUpload
                        item={{}}
                        style={{ width: '100px', height: '100px', marginLeft: '-10px' }}
                        value={records.img}
                        onChange={(data) => {
                          records.img = data
                        }}
                      />
                    </div>
                  )
                },
              },
              {
                title: '链接',
                dataIndex: 'url',
                width: 200,
                customRender: function (text, records, index, h) {
                  return (
                    <Input
                      item={{}}
                      style={{ width: '100%' }}
                      value={records.url}
                      onChange={(data) => {
                        records.url = data.target.value
                      }}
                    />
                  )
                },
              },
              {
                title: '操作',
                type: 'buttonGroup',
                align: 'center',
                typeData: ({ records, index }) => {
                  return [
                    {
                      name: '删除',
                      popTitle: '删除',
                      type: 'pop',
                      display: true,
                      isSafe: true,
                      onClick: () => {
                        this.form.data.splice(index, 1)
                        this.$forceUpdate()
                      },
                    },
                  ]
                },
              },
            ],
          },
        ]}
      />
    )
  },
}
</script>