/**
 * 写cookies
 * @param {string} name 写cookie的key
 * @param {string|number} value 写cookie的值
 * @param {number} day 存储的时间，默认30天
 */
export const setCookie = (
  name,
  value,
  day = 30,
  domain = `.${location.hostname
    .split('.')
    .slice(1)
    .join('.')}`
) => {
  const exp = new Date()
  exp.setTime(exp.getTime() + day * 24 * 60 * 60 * 1000)
  window.document.cookie = `${name}=${value.toString()};path=/;domain=${domain};expires=${exp.toUTCString()}`
}

/**
 * 读取cookies
 * @param {string} name 要获取的cookie名称
 * @param {number|boolean} type 是否直接获取对应的值，若存入真值，则直接返回，否则进行解码
 */
export const getCookie = name => {
  const reg = new RegExp(`(^| )${name}=([^;]*)(;|$)`)
  const arr = window.document.cookie.match(reg)
  if (arr) {
    return arr[2]
  }
  return null
}

/**
 * 删除cookie
 * @param name 删除的cookie名称
 */
export const delCookie = (
  name,
  domain = `.${location.hostname
    .split('.')
    .slice(1)
    .join('.')}`
) => {
  if (!name) return
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${domain}`
}

//清除所有cookie函数
function clearAllCookie() {
  var keys = document.cookie.match(/[^ =;]+(?=\=)/g)
  if (keys) {
    for (var i = keys.length; i--; ) {
      document.cookie = `${keys[i]}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
    }
  }
}
