<script>
import { DatePicker } from 'ant-design-vue'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
export default {
  props: ['value', 'mode', 'item'],
  render() {
    return (
      <DatePicker
        props={this.item.props}
        style={{ width: '100%' }}
        disabled={this.mode == 'detail'}
        value={this.value}
        locale={locale}
        onChange={value => {
          if (this.item.formatdate && typeof this.item.formatdate === 'function') {
            let formatdate = this.item.formatdate(value)
            this.$emit('change', formatdate)
          } else {
            this.$emit('change', value)
          }
        }}
      />
    )
  }
}
</script>
