<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      select: -1
    }
  },
  render() {
    const icon = [
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20230%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20231%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20232%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20233%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20234%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20235%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20236%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20237%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20238%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20240%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20241%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20242%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20243%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20244%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20245%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20246%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20247%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20248%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20249%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20250%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20251%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20252%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20346%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20347%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20348%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20349%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20350%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20351%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20352%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20353%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20354%403x.png',
      'https://qefile.nongqibang.com/%E8%92%99%E7%89%88%E7%BB%84%20355%403x.png'
    ]
    return (
      <div class="icon-class">
        {icon.map(e => {
          const isFocus = e == this.value
          return (
            <img
              src={e}
              class="icons-item"
              style={{
                margin: '10px',
                border: isFocus ? '1px solid #A0A0A0' : 'none',
                borderRadius: '4px'
              }}
              onClick={() => this.$emit('change', e)}
            />
          )
        })}
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.icon-class {
  display: flex;
  flex-wrap: wrap;
}
.icons-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  min-height: 40px;
  min-width: 40px;
  max-width: 40px;
  max-height: 40px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
