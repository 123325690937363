<script>
import { Input } from 'ant-design-vue'
export default {
  props: ['item', 'value', 'mode'],
  render() {
    return (
      <Input.Search
        props={this.item.props}
        disabled={this.mode == 'detail'}
        value={this.value}
        placeholder={this.item.placeholder ? this.item.placeholder : '请输入' + (this.item.name || this.item.label)}
        onChange={e => this.$emit('change', e.target.value)}
        style={{ width: '100%' }}
        maxLength={40}
        {...this.item.props}
      />
    )
  }
}
</script>
