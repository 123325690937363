<script>
import apiTool from '@/command/apiTool'
import { Button } from 'ant-design-vue'
import FormVideoGroup from '@/components/RichComponent/videoGroup'
import FormImageGrid from '@/components/RichComponent/imageGrid'
import video from './assets/video.png'
import grid from './assets/grid.png'
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    // value: {
    //   handler(newValue, oldValue) {
    //     if (newValue !== oldValue) {
    //       setTimeout(() => {
    //         this.ue.setContent(newValue)
    //       }, 1000)
    //     }
    //   },
    //   deep: true
    //   //   immediate: true
    // }
  },
  mounted() {
    // 高度需要固定时加 body{max-height: 500px;overflow: hidden !important;overflow-y: auto !important;}
    this.ue = new UE.ui.Editor({
      allowDivTransToP: false,
      initialStyle: 'p{line-height:1.4em;margin:0px;}',
      key: 'editor',
      catchRemoteImageEnable: false,
      iframeJsUrl: '/rich-components.umd.js?231018',
      toolbars: [
        [
          // 'anchor', // 锚点
          'undo', // 撤销
          'redo', // 重做
          'bold', // 加粗
          'indent', // 首行缩进
          // 'snapscreen', // 截图
          'italic', // 斜体
          'underline', // 下划线
          'strikethrough', // 删除线

          'subscript', // 下标
          'fontborder', // 字符边框
          'superscript', // 上标
          'formatmatch', // 格式刷
          'source', // 源代码
          'blockquote', // 引用
          // 'pasteplain', // 纯文本粘贴模式
          'selectall', // 全选
          'print', // 打印
          // 'preview', // 预览
          'horizontal', // 分隔线
          'removeformat', // 清除格式
          'time', // 时间
          'date', // 日期
          'unlink', // 取消链接
          'insertrow', // 前插入行
          'insertcol', // 前插入列
          'mergeright', // 右合并单元格
          'mergedown', // 下合并单元格
          'deleterow', // 删除行
          'deletecol', // 删除列
          'splittorows', // 拆分成行
          'splittocols', // 拆分成列
          'splittocells', // 完全拆分单元格
          'deletecaption', // 删除表格标题
          'inserttitle', // 插入标题
          'mergecells', // 合并多个单元格
          'deletetable', // 删除表格
          'cleardoc', // 清空文档
          'insertparagraphbeforetable', // "表格前插入行"
          'insertcode', // 代码语言
          'fontfamily', // 字体
          'fontsize', // 字号
          'paragraph', // 段落格式
          'backcolor', // 背景
          'customcard',
          'button', // 单图上传
          'customvideogroup', // 自定义标题
          'customimagegrid', // 自定义标题
          // 'insertimage', // 多图上传
          // 'edittable', // 表格属性
          // 'edittd', // 单元格属性
          // 'link', // 超链接
          // 'emotion', // 表情
          // 'spechars', // 特殊字符
          // 'searchreplace', // 查询替换
          // 'map', // Baidu地图
          // 'gmap', // Google地图
          'video', // 视频
          // 'insertvideo', // 视频
          // 'help', // 帮助
          'justifyleft', // 居左对齐
          'justifyright', // 居右对齐
          'justifycenter', // 居中对齐
          'justifyjustify', // 两端对齐
          'forecolor', // 字体颜色
          'backcolor', // 背景色
          'insertorderedlist', // 有序列表
          'insertunorderedlist', // 无序列表
          'fullscreen', // 全屏
          'directionalityltr', // 从左向右输入
          'directionalityrtl', // 从右向左输入
          'rowspacingtop', // 段前距
          'rowspacingbottom', // 段后距
          'pagebreak', // 分页
          // 'insertframe', // 插入Iframe
          'imagenone', // 默认
          'imageleft', // 左浮动
          'imageright', // 右浮动
          // 'attachment', // 附件
          'imagecenter', // 居中
          'wordimage', // 图片转存
          'lineheight', // 行间距
          'edittip ', // 编辑提示

          'autotypeset', // 自动排版
          // 'webapp', // 百度应用
          'touppercase', // 字母大写
          'tolowercase', // 字母小写

          // 'template', // 模板
          // 'scrawl', // 涂鸦
          // 'music', // 音乐
          'inserttable', // 插入表格
          'drafts', // 从草稿箱加载
        ],
      ],
    })
    let that = this
    UE.registerUI('button', function (editor, uiName) {
      let btn = new UE.ui.Button({
        // 按钮的名字
        name: '图片上传',
        // 提示
        title: '图片上传',
        // 添加额外样式，指定icon图标，这里默认使用一个重复的icon
        cssRules: 'background-position: -381px 0px;',
        // 点击时执行的命令
        onclick: function () {
          apiTool.uploadFile({
            success: (data) => {
              let imgs = ''
              for (let index = 0; index < data.length; index++) {
                imgs = imgs + `<p><img style="display: block !important;width:100%;" src="${data[index]}"/></p>`
              }
              that.ue.execCommand('inserthtml', imgs)
              // that.ue.setContent(`<img style="display: block !important;" src="${data}"/>`, true)
            },
          })
        },
      })
      return btn
    })
    UE.registerUI('customborder', function (editor, uiName) {
      let btn = new UE.ui.Button({
        // 按钮的名字
        name: '自定义边框',
        // 提示
        title: '自定义边框',
        // 添加额外样式，指定icon图标，这里默认使用一个重复的icon
        cssRules: 'background-image:url()',
        onclick: function () {
          that.ue.execCommand(
            'inserthtml',
            `
            <div style="border:1px solid black;height:100%;position:fixed;left:0;top:0px;"></div>
          `
          )
        },
      })
      return btn
    })
    UE.registerUI('customimagegrid', function (editor, uiName) {
      let btn = new UE.ui.Button({
        // 按钮的名字
        name: '图片栅格',
        // 提示
        title: '图片栅格',
        // 添加额外样式，指定icon图标，这里默认使用一个重复的icon
        cssRules: `background-image:url(${grid});background-size:100% 100%;`,
        onclick: function () {
          apiTool.showModal({
            title: '图片栅格组',
            width: 1000,
            view: FormImageGrid,
            success: ({ data, setHidden }) => {
              const background = data.bkColor || 'white'
              const padding = data.padding ? data.padding + 'px' : '0px'
              const radius = data.radius ? data.radius + 'px' : '0px'
              const margin = data.margin ? data.margin + 'px' : '0px'
              that.ue.execCommand(
                'inserthtml',
                `
                <block style="overflow:hidden;display:block;">
                  <p style="background:${background};padding:${padding};border-radius:${radius};margin:${margin}">
                    <rich-image-grid data='${JSON.stringify(data.data)}' type='${data.type}'>
                    </rich-image-grid>
                  </p>
                </block>
              `
              )
              setHidden()
            },
            foot: ({ close, submit }) => {
              return [
                {
                  name: '返回',
                  onClick: close,
                },
                {
                  name: '确认',
                  type: 'primary',
                  onClick: submit,
                },
              ]
            },
          })
        },
      })
      return btn
    })
    UE.registerUI('customvideogroup', function (editor, uiName) {
      let btn = new UE.ui.Button({
        // 按钮的名字
        name: '视频组',
        // 提示
        title: '视频组',
        // 添加额外样式，指定icon图标，这里默认使用一个重复的icon
        cssRules: `background-image:url(${video});background-size:100% 100%;`,
        onclick: function () {
          apiTool.showModal({
            title: '视频组',
            width: 1000,
            view: FormVideoGroup,
            success: ({ data, setHidden }) => {
              const background = data.bkColor || 'white'
              const padding = data.padding ? data.padding + 'px' : '0px'
              const radius = data.radius ? data.radius + 'px' : '0px'
              const margin = data.margin ? data.margin + 'px' : '0px'
              that.ue.execCommand(
                'inserthtml',
                `
                <block style="overflow:hidden;display:block;">
                  <p style="background:${background};padding:${padding};border-radius:${radius};margin:${margin}">
                    <rich-video-swiper data='${JSON.stringify(data.video)}'>
                    </rich-video-swiper>
                  </p>
                </block>
              `
              )
              setHidden()
            },
            foot: ({ close, submit }) => {
              return [
                {
                  name: '返回',
                  onClick: close,
                },
                {
                  name: '确认',
                  type: 'primary',
                  onClick: submit,
                },
              ]
            },
          })
        },
      })
      return btn
    })
    UE.registerUI('video', function (editor, uiName) {
      let btn = new UE.ui.Button({
        // 按钮的名字
        name: '视频上传',
        // 提示
        title: '视频上传',
        // 添加额外样式，指定icon图标，这里默认使用一个重复的icon
        cssRules: 'background-position: -321px -20px;',
        // 点击时执行的命令
        onclick: function () {
          apiTool.uploadVideoFile({
            success: (data) => {
              let video = `<video style="display: block !important;width: 100%;max-width: 580px;" src="${data[0]}" webkit-playsinline="true" playsinline="true" controls="true" /> 1`
              that.ue.execCommand('inserthtml', video)
              // that.ue.setContent('<div></div>', true)
            },
          })
        },
      })
      return btn
    })
    this.ue.addListener('resize', function (editor) {
      console.log('afterpaste')
    })
    this.ue.addListener('contentChange', function (editor) {
      let content = that.ue.getContent()
      that.$emit('change', content)
    })
    this.ue.render(this.$refs['editor'])
    this.ue.ready(function () {
      const this_ = this
      console.log(this_.document)
      this_.document.addEventListener('paste', () => {
        setTimeout(() => {
          that.ue.fireEvent('contentChange')
        }, 1000)
      })
      // this_.paste((e) => {
      //   console.log(312)
      //   // for (var i = 0; i < items.length; ++i) {
      //   //   var item = items[i]
      //   //   console.log('富文本复制 item', item)
      //   //   if (
      //   //     items.length < 2 &&
      //   //     item.kind == 'file' &&
      //   //     (item.type == 'image/png' || item.type == 'image/jpeg' || item.type == 'image/jpg')
      //   //   ) {
      //   //     var imgFile = item.getAsFile()
      //   //     if (!imgFile) {
      //   //       return true
      //   //     }
      //   //     // 大小不能超过2m
      //   //     if (imgFile.size > 2 * 1024 * 1024) {
      //   //       that.$message.error('图片大小为:' + (imgFile.size / 1024 / 1024).toFixed(2) + 'M,超过2M不能粘贴!')
      //   //       return false
      //   //     }
      //   //     var reader = new FileReader()
      //   //     reader.readAsDataURL(imgFile)
      //   //     reader.onload = function (e) {
      //   //       console.log(this.result, 'this.result')
      //   //       // 显示文件
      //   //       that.ue.execCommand('inserthtml', '<img src="111" alt="" />')
      //   //     }
      //   //     return false
      //   //   }
      //   // }
      // })
    })
    this.ue.ready(() => {
      setTimeout(() => {
        console.log('this.value', this.value)
        if (this.value) {
          that.ue.setContent(this.value)
        } else {
          that.ue.setContent('<p>' + '</p>')
        }
      }, 1000)
    })
  },
  methods: {
    // async getClipboardContent() {
    //   try {
    //     const clipboardItems = await navigator.clipboard.readText()
    //     for (const clipboardItem of clipboardItems) {
    //       for (const type of clipboardItem.types) {
    //         if (type === 'image/png' || type === 'image/jpeg') {
    //           const blob = await clipboardItem.getType(type)
    //           const imageUrl = URL.createObjectURL(blob)
    //           this.clipboardImage = imageUrl
    //           console.log('剪贴板图像URL:', imageUrl)
    //           return
    //         }
    //       }
    //     }
    //     console.log('剪贴板中没有图像')
    //   } catch (error) {
    //     console.log('获取剪贴板图像失败:', error)
    //   }
    // },
    wxClick() {
      if (this.item.onWxClick) {
        this.item.onWxClick()
      }
    },
  },
  render() {
    return (
      <div class="tinymce-wrap">
        <div class="editorBox">
          {/* this.item.simulator && (
            <div class="editorBox-buttons">
              <Button type="primary" onClick={() => this.wxClick()}>
                微信图文采集
              </Button>
            </div>
          ) */}
          <div ref="editor" class="editor"></div>
        </div>
        {this.item.simulator && (
          <div class="phoneBox">
            <img src="https://qfile.nongqibang.com/phoneTop.png" class="phone-top" />
            <div class="tinymce-preview" ref="phone" domProps={{ innerHTML: this.value }}></div>
          </div>
        )}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.tinymce-wrap {
  display: flex;
  .editorBox {
    flex: 1;
    .editorBox-buttons {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 5px;
    }
  }
}
.editor {
  max-width: 100%;
  min-height: 300px !important;
  /deep/.edui-box {
    height: 20px !important;
    max-height: 20px !important;
    .edui-default {
      height: 100%;
    }
  }
  /deep/.edui-editor-iframeholder.edui-default {
    width: 100% !important;
    // max-height: 500px !important; //高度需要固定时加
  }
  /deep/.edui-editor.edui-default {
    width: 100% !important;
  }
  /deep/.edui-toolbar.edui-default {
    width: 100%;
    height: 45px !important;
    display: flex;
    flex-wrap: wrap;
  }
  /deep/.page-content {
    padding: none !important;
  }
}
.phoneBox {
  overflow: hidden;
  width: 320px;
  height: 590px;
  border: 1px solid rgb(51, 51, 51);
  margin-left: 20px;
  .phone-top {
    width: 100%;
    height: 64px;
    vertical-align: top;
  }
  .tinymce-preview {
    width: 100%;
    height: 526px;
    padding: 10px;
    overflow: hidden;
    overflow-y: auto;
    word-break: break-all;
    /deep/ img {
      max-width: 100% !important;
    }
  }
}
</style>
