import router from './router'
import store from './store'
import storage from 'store'
import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import notification from 'ant-design-vue/es/notification'
import message from 'ant-design-vue/es/message'
import { setDocumentTitle, domTitle } from '@/utils/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { i18nRender } from '@/locales'
import { getAction } from './command/netTool'
import { getCookie } from '@/utils/cookie'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const allowList = ['login', 'register', 'register', 'loginDYZ'] // no redirect allowList
const loginRoutePath = '/user/login'
const getAppCode = function () {
  return new Promise((resolve, reject) => {
    getAction('/farmTenant/myList').then(res => {
      if (res.code == 200) {
        let arr = res.data.filter(s => s)
        if (arr.length > 0) {
          localStorage.setItem('tenantArr', JSON.stringify(arr))
          localStorage.setItem('appCode', arr[0]['tenantId'])
          localStorage.setItem('logo', arr[0].sysIcon || '')
          getAction('/admin/menu/userTree?sysCode=sznc', {}, '/bpi').then(e => {
            if (e.data) {
              router.push({
                path: `/${e['data'][0]['path'].replace('/', '')}`
              })
              resolve()
            } else {
              message.warning('您暂无权限，不可进入！')
            }
          })
        } else {
          message.error('没有租户不可登入！')
          return
        }
      } else {
        message.error(res.msg)
      }
    })
  })
}
const getRouter = function (to, from, next) {
  if (to.path === loginRoutePath) {
    next()
    NProgress.done()
  } else {
    // check login user.roles is null
    if (store.getters.roles.length === 0) {
      // request login userInfo
      store
        .dispatch('GetInfo')
        .then(res => {
          const roles = res.result && res.result.role
          // generate dynamic router
          store.dispatch('GenerateRoutes', { roles }).then(() => {
            // 根据roles权限生成可访问的路由表
            // 动态添加可访问路由表
            router.addRoutes(store.getters.addRouters)
            // 请求带有 redirect 重定向时，登录自动重定向到该地址
            const redirect = decodeURIComponent(from.query.redirect || to.path)
            if (to.path === redirect) {
              // set the replace: true so the navigation will not leave a history record
              next({ ...to, replace: true })
            } else {
              // 跳转到目的路由
              next({ path: redirect })
            }
          })
        })
        .catch(err => {
          console.log('err', err)
          notification.error({
            message: '错误',
            description: '请求用户信息失败，请重试'
          })
          // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
          store.dispatch('Logout').then(() => {
            next({ path: loginRoutePath, query: { redirect: to.fullPath } })
          })
        })
    } else {
      if (process.env.NODE_ENV !== 'development' && to.path !== '/personalsettingDetail' && to.name !== 'login' && to.name !== 'loginDYZ') {
        getAction('/admin/my/queryPwdInfo', {}, '/bpi').then(r => {
          let { passwordModifyState } = r.data
          if (passwordModifyState == '2') {
            router.push({ name: 'personalsettingDetail' })
          } else {
            next()
          }
        })
      } else {
        next()
      }
    }
  }
}

router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(`${i18nRender(to.meta.title)} - ${domTitle}`)
  /* has token */
  let access_token = getCookie('access_token')
  let bd_access_token = storage.get(ACCESS_TOKEN)
  if (bd_access_token || access_token) {
    if (access_token && [null, 'login'].includes(to.name) && !bd_access_token) {
      storage.set(ACCESS_TOKEN, access_token.replace(/\"/g, ''), 7 * 24 * 60 * 60 * 1000)
      store.commit('SET_TOKEN', access_token)
      localStorage.setItem('Access-Token', `${access_token}`)
      getRouter(to, from, next)
      // getAppCode().then(() => {
      //   getRouter(to, from, next)
      // })
    } else {
      getRouter(to, from, next)
    }
  } else {
    if (allowList.includes(to.name)) {
      // 在免登录名单，直接进入
      next()
    } else {
      next({ path: loginRoutePath, query: { redirect: to.fullPath } })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
