import { getAction } from '@/command/netTool'
import { getFilterList } from '@/command/apiTool'

const textFiles = {
  state: {
    byProductType: [],
    byGoodsType: [],
    byTicketType: [],
    byBrandType: [],
    byStarHotelType: [],
    bySubjectType: [],
    byHotelType: [],
    byHotelBreakFastType: [],
    byWindowType: [],
    byBedType: [],
    byWIFIType: [],
    byWhetherType: [],
    byExtraBedType: [],
    byBroadbandType: [],
    byCertificateType: [],
    byCompanyCertType: [],
    bySubMerClass: [],
    bySubMerContactType: [],
    bySzxcApplyService: [],
    bySubMerType: [],
    byAuditType: [],
    suitNum: [],
  },

  mutations: {
    SET_PRODUCT_TYPE(state, data) {
      state.byProductType = data
    },
    SET_GOODS_TYPE(state, data) {
      state.byGoodsType = data
    },
    SET_Ticket_TYPE(state, data) {
      state.byTicketType = data
    },
    SET_Brand_TYPE(state, data) {
      state.byBrandType = data
    },
    SET_StarHotel_TYPE(state, data) {
      state.byStarHotelType = data
    },
    SET_Subject_TYPE(state, data) {
      state.bySubjectType = data
    },
    SET_Hotel_TYPE(state, data) {
      state.byHotelType = data
    },
    SET_HotelBreakFast_TYPE(state, data) {
      state.byHotelBreakFastType = data
    },
    SET_Window_TYPE(state, data) {
      state.byWindowType = data
    },
    SET_Bed_TYPE(state, data) {
      state.byBedType = data
    },
    SET_WIFI_TYPE(state, data) {
      state.byWIFIType = data
    },
    SET_Whether_TYPE(state, data) {
      state.byWhetherType = data
    },
    SET_ExtraBed_TYPE(state, data) {
      state.byExtraBedType = data
    },
    SET_Broadband_TYPE(state, data) {
      state.byBroadbandType = data
    },
    SET_Certificate_Type(state, data) {
      state.byCertificateType = data
    },
    SET_Company_Cert_Type(state, data) {
      state.byCompanyCertType = data
    },
    SET_Sub_Mer_Class(state, data) {
      state.bySubMerClass = data
    },
    SET_Sub_Mer_Contact_Type(state, data) {
      state.bySubMerContactType = data
    },
    SET_Szxc_Apply_Service(state, data) {
      state.bySzxcApplyService = data
    },
    SET_Sub_Mer_Type(state, data) {
      state.bySubMerType = data
    },
    SET_Audit_Type(state, data) {
      state.byAuditType = data
    },
    SET_Suit_Num(state, data) {
      state.suitNum = data
    },
  },

  actions: {
    //* 针对产品类型
    getProductType({ commit }) {
      getAction('/common/order/queryOrderFormTypeList', {}, '/api').then((res) => {
        const { data } = res
        commit('SET_PRODUCT_TYPE', getFilterList(data, 'desc', 'code'))
      })
    },
    //* 商品类型
    getGoodsType({ commit }) {
      getAction('/market/farmBanner/getGoodsTypeList', {}, '/api').then((res) => {
        const { data } = res
        commit('SET_GOODS_TYPE', getFilterList(data, 'name', 'type'))
      })
    },
    //* 票种
    getTicketType({ commit }) {
      getAction(
        '/admin/dict/codes?codes=Ticket_type,brand,window,bed_type,WIFI,Whethersmokingisprohibited,Extrabedsareavailable,Broadband_situation,farm_suit_num',
        {},
        '/bpi'
      ).then((res) => {
        let data = res.data
        let {
          Ticket_type,
          brand,
          window,
          bed_type,
          WIFI,
          Whethersmokingisprohibited,
          Extrabedsareavailable,
          Broadband_situation,
          farm_suit_num,
        } = data
        commit('SET_Ticket_TYPE', getFilterList(Ticket_type))
        commit('SET_Brand_TYPE', getFilterList(brand))
        commit('SET_Window_TYPE', getFilterList(window))
        commit('SET_Bed_TYPE', getFilterList(bed_type))
        commit('SET_WIFI_TYPE', getFilterList(WIFI))
        commit('SET_Whether_TYPE', getFilterList(Whethersmokingisprohibited))
        commit('SET_ExtraBed_TYPE', getFilterList(Extrabedsareavailable))
        commit('SET_Broadband_TYPE', getFilterList(Broadband_situation))
        commit('SET_Suit_Num', getFilterList(farm_suit_num))
      })
    }, //* 酒店
    getHotelType({ commit }) {
      getAction('/admin/dict/codes?codes=star_hotel,hotel_subject,hotel_type,hotel_break_fast', {}, '/bpi').then(
        (res) => {
          let data = res.data
          let { star_hotel, hotel_subject, hotel_type, hotel_break_fast } = data
          commit('SET_StarHotel_TYPE', getFilterList(star_hotel))
          commit('SET_Subject_TYPE', getFilterList(hotel_subject))
          commit('SET_Hotel_TYPE', getFilterList(hotel_type))
          commit('SET_HotelBreakFast_TYPE', getFilterList(hotel_break_fast))
        }
      )
    },
    //* 二级商户相关
    getSecondaryType({ commit }) {
      getAction(
        '/admin/dict/codes?codes=certificate_type,company_cert_type,sub_mer_class,sub_mer_contact_type,szxc_apply_service,sub_mer_type,audit_type',
        {},
        '/bpi'
      ).then((res) => {
        let data = res.data
        let {
          certificate_type,
          company_cert_type,
          sub_mer_class,
          sub_mer_contact_type,
          szxc_apply_service,
          sub_mer_type,
          audit_type, // 反馈类型
        } = data
        commit('SET_Certificate_Type', getFilterList(certificate_type))
        commit('SET_Company_Cert_Type', getFilterList(company_cert_type))
        commit('SET_Sub_Mer_Class', getFilterList(sub_mer_class))
        commit('SET_Sub_Mer_Contact_Type', getFilterList(sub_mer_contact_type))
        commit('SET_Szxc_Apply_Service', getFilterList(szxc_apply_service))
        commit('SET_Sub_Mer_Type', getFilterList(sub_mer_type))
        commit('SET_Audit_Type', getFilterList(audit_type))
      })
    },
  },
}

export default textFiles
