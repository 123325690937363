import { render, staticRenderFns } from "./BasicLayout.vue?vue&type=template&id=df7d12a6&scoped=true"
import script from "./BasicLayout.vue?vue&type=script&lang=js"
export * from "./BasicLayout.vue?vue&type=script&lang=js"
import style0 from "./BasicLayout.vue?vue&type=style&index=0&id=df7d12a6&prod&lang=less"
import style1 from "./BasicLayout.vue?vue&type=style&index=1&id=df7d12a6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df7d12a6",
  null
  
)

export default component.exports