import request from '@/utils/request'
import { Message } from 'ant-design-vue'

/**
 * 获取type类型
 */
export const getTypeList = function getTypeList({ url = this.listUrl } = {}) {
  request({
    url,
    method: 'GET'
  }).then(e => {
    this.listUrl = url
    this.tagData = e.data
  })
}

/**
 * 添加标签
 */
export const addType = function addType({ url, params, isPost = true } = {}) {
  return new Promise(resolve => {
    request({
      url,
      method: 'POST',
      data: params
    }).then(result => {
      if (result.code == 200) {
        Message.success('添加成功')
        isPost && getTypeList.call(this)
        resolve()
      } else {
        Message.error(result.msg)
      }
    })
  })
}

/**
 * 编辑标签
 */
export const editType = function addType({ url, params, isPost = true } = {}) {
  return new Promise(resolve => {
    request({
      url,
      method: 'PUT',
      data: params
    }).then(result => {
      if (result.code == 200) {
        Message.success('编辑成功')
        isPost && getTypeList.call(this)
        resolve()
      } else {
        Message.error(result.msg)
      }
    })
  })
}

/**
 * 删除标签
 */
export const deleteType = function addType({ url, isPost = true } = {}) {
  return new Promise(resolve => {
    request({
      url,
      method: 'DELETE'
    }).then(result => {
      if (result.code == 200) {
        Message.success('删除成功')
        isPost && getTypeList.call(this)
        resolve()
      } else {
        Message.error(result.msg)
      }
    })
  })
}

/**
 * 获取省列表
 */
export const getProvinceList = function getProvinceList({ url, params }) {
  return new Promise(resolve => {
    request({
      url,
      method: 'GET',
      params
    }).then(e => {
      resolve(e.data)
    })
  })
}

/**
 * 新增模板
 */
export const addTemplate = function addTemplate({ url, params }) {
  return new Promise(resolve => {
    request({
      url,
      method: 'POST',
      params
    }).then(e => {
      this.detail = e.data
      resolve()
    })
  })
}

/**
 * 删除详情
 */
export const deleteDetail = function deleteDetail({ url, params }) {
  return new Promise(resolve => {
    request({
      url,
      method: 'POST',
      params
    }).then(() => {
      Message.success('删除成功')
      resolve()
    })
  })
}

export default {
  getTypeList,
  addType,
  deleteType,
  editType,
  getProvinceList,
  addTemplate,
  deleteDetail
}
