<script>
import { Radio } from 'ant-design-vue'
export default {
  props: ['typeData', 'value', 'mode', 'item'],
  methods: {
    renderRadio(item) {
      return (
        <Radio value={item.value} disabled={item.disabled} style={item.style}>
          {typeof item.name == 'string' && item.name}
          {typeof item.name == 'function' && item.name()}
        </Radio>
      )
    },
  },
  render() {
    return (
      <Radio.Group
        disabled={this.mode == 'detail'}
        value={this.value}
        onChange={(e) => {
          this.$emit('change', e.target.value)
          this.item.onChange && this.item.onChange(e.target.value)
        }}
      >
        {(this.typeData || []).map((e) => this.renderRadio(e))}
      </Radio.Group>
    )
  },
}
</script>
