<script>
import { InputNumber } from 'ant-design-vue'
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Number,
      default: 0,
    },
    mode: {
      type: String,
      default: null,
    },
  },
  render() {
    return (
      <div style={this.item.styleBox}>
        <InputNumber
          props={this.item.props}
          disabled={this.mode == 'detail' || this.item.disabled}
          value={this.value || 0}
          placeholder={this.item.placeholder ?? '请输入' + (this.item.name || this.item.label)}
          onChange={(e) => this.$emit('change', e)}
          style={{ width: '100%', ...this.item.styles }}
        />
        <div style={this.item.styles}>{this.item.desc}</div>
      </div>
    )
  },
}
</script>
