<script>
import { TimePicker } from 'ant-design-vue'
import moment from 'moment'
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: moment('00:00:00', 'HH:mm'),
    },
  },
  data() {
    return {
      time: [],
    }
  },
  methods: {
    getValue() {
      if (typeof this.value == 'string') {
        return this.value.split('-')
      } else {
        return ['', '']
      }
    },
  },
  render() {
    const time = this.getValue()
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TimePicker
          onChange={(data) => {
            this.time = this.getValue()
            this.time[0] = data
            this.$emit('change', this.time.join('-'))
          }}
          props={this.item.props}
          {...this.item.props}
          value={time[0]}
          style={{ width: '100%' }}
        />
        <TimePicker
          onChange={(data) => {
            this.time = this.getValue()
            this.time[1] = data
            this.$emit('change', this.time.join('-'))
          }}
          props={this.item.props}
          {...this.item.props}
          value={time[1]}
          style={{ width: '100%', marginLeft: '10px' }}
        />
      </div>
    )
  },
}
</script>
