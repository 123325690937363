<script>
import { Upload, Modal } from 'ant-design-vue'
export default {
  props: ['value', 'item', 'mode', 'styles'],
  data() {
    return {
      previewVisible: false,
      previewImage: '',
    }
  },
  methods: {
    handlePreview(file) {
      if (!file.url) return
      this.previewImage = file.url
      this.previewVisible = true
    },
    getImgUrl(list) {
      // '?imageView2/0/format/jpg/q/75'
      const arr = []
      const { url } = JSON.parse(localStorage.getItem('qiniu')) || {}
      list.forEach((e, i) => {
        if (e.status == 'done') {
          if (this.item.multiple && this.item.maxLength && +this.item.maxLength < i + 1) {
            this.$message.warning(`限制${this.item.maxLength}张`)
            return arr.join(',')
          }
          if (e.hash) {
            arr.push(url + e.hash)
          } else if (e.url) {
            arr.push(e.url)
          } else {
            if (e.response && e.response.hash) {
              arr.push(url + e.response.hash)
            }
          }
        }
      })

      return arr.join(',')
    },
    getFileList(url) {
      if (Array.isArray(url)) return url
      if (!url) return []
      return url
        .split(',')
        .filter((e) => e)
        .map((e) => {
          if (!e) return
          const name = e.split('?')[0].split('/')[3]
          return {
            uid: 'vc-upload-' + name,
            name,
            status: 'done',
            url: e,
            thumbUrl: e,
          }
        })
    },
    handleChange(data) {
      let arr = data.fileList.slice(0, 20)
      if (arr.some((e) => e.status == 'uploading')) {
        this.$emit('change', arr)
      } else {
        this.$emit('change', this.getImgUrl(arr))
      }
    },
    renderButton() {
      return (
        <div>
          <a-icon type="plus" />
          <div class="ant-upload-text">上传</div>
        </div>
      )
    },
    renderUploadItem(file) {
      if (file.indexOf('mp4') !== -1) {
        return <video src={file} />
      }
      return <img src={file} />
    },
    beforeUpload(file) {
      if (this.item.pass) return true
      // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
      // if (!isJpgOrPng) {
      //   this.$message.error('请上传图片文件（jpeg/png/jpg）')
      //   return false
      // }
      const isLt4M = file.size / 1024 / 1024 < 4
      // if (isJpgOrPng && !isLt4M) {
      //   this.$message.error('图片资源文件体积不能超过4M')
      // }
      if (!isLt4M) {
        this.$message.error('资源文件体积不能超过4M')
      }
      // return isJpgOrPng && isLt4M
      return isLt4M
    },
  },
  render() {
    const { token } = JSON.parse(localStorage.getItem('qiniu')) || {}
    return (
      <div>
        <Upload
          name="file"
          action="//upload.qiniup.com/"
          list-type="picture-card"
          fileList={this.getFileList(this.value)}
          accept={this.item.accept ?? 'text/img'}
          disabled={this.mode == 'detail' || this.item.disabled}
          class="uploader"
          data={{ token }}
          onChange={this.handleChange}
          style={this.styles}
          renderUploadItem={this.renderUploadItem}
          beforeUpload={this.beforeUpload}
          onPreview={this.handlePreview}
          multiple={this.item.multiple}
        >
          {(this.getFileList(this.value) || []).length < (this.item.maxLength || 1) && this.renderButton()}
        </Upload>

        <Modal visible={this.previewVisible} footer={null} onCancel={() => (this.previewVisible = false)}>
          {this.previewImage.indexOf('mp4') != -1 ? (
            <video src={this.previewImage} style={{ width: '100%', height: '100%', objectFit: 'fill' }} controls />
          ) : (
            <img alt="example" style="width: 100%" src={this.previewImage} />
          )}
        </Modal>
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.uploader {
  margin-left: 0px !important;
}
/deep/.ant-upload-list-item-thumbnail img{
    -o-object-fit: contain;
    object-fit: contain;
}
</style>
