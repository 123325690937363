<script>
import { Button, Col, Input, Row } from 'ant-design-vue'
import apiTool from '@/command/apiTool'
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    setValue: {
      type: Function,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    onChange: {
      type: Function,
      default: null,
    },
  },
  methods: {
    onOpenMap() {
      apiTool.openMap({
        title: '打开地图',
        value: [],
        onSuccess: (data) => {
          const { lat, lng } = data
          let text = `${lat}，${lng}`
          this.setValue('lat', lat)
          this.setValue('lng', lng)
          this.setValue('lon', lng)
          this.$emit('change', text)
          this.item.onChange && this.item.onChange(data)
          this.$forceUpdate()
        },
      })
    },
  },
  render() {
    return (
      <Row gutter={10}>
        <Col span={20}>
          <Input value={this.value} placeholder="请选择定位" disabled={true} />
        </Col>
        <Col span={4}>
          <Button type="primary" onClick={this.onOpenMap}>
            打开定位
          </Button>
        </Col>
      </Row>
    )
  },
}
</script>
