<script>
import { Modal } from 'ant-design-vue'
export default {
  props: {
    value: {
      type: Array || String,
      default: null,
    },
  },
  data() {
    return {
      previewVisible: false,
      previewImage: '',
    }
  },
  methods: {
    renderItem(item) {
      return (
        <img
          src={item}
          onClick={() => {
            this.previewVisible = true
            this.previewImage = item
          }}
        />
      )
    },
    getValue() {
      if (typeof this.value == 'string') {
        return this.value.split(',')
      } else if (Array.isArray(this.value)) {
        return this.value
      } else {
        return []
      }
    },
  },
  render() {
    return (
      <div class="image-group">
        {this.getValue().map((e) => {
          return this.renderItem(e)
        })}
        <Modal visible={this.previewVisible} footer={null} onCancel={() => (this.previewVisible = false)}>
          <img alt="example" style="width: 100%" src={this.previewImage} />
        </Modal>
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.image-group {
  display: flex;
  flex-wrap: wrap;
  img {
    width: 155px;
    height: 155px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    object-fit: cover;
  }
}
</style>
