<script>
import FormView from '@/components/FormView'
import FormUploadVideo from '@/components/FormView/FormUploadVideo'

export default {
  data() {
    return {
      form: {
        padding: '',
        radius: '',
        bkColor: 'white',
        video: [],
      },
    }
  },
  methods: {
    sumbit() {
      this.$emit('success', this.form)
    },
  },
  render() {
    return (
      <FormView
        form={this.form}
        layout={'horizontal'}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        data={[
          {
            name: '外边距',
            type: 'inputNumber',
            key: 'margin',
            labelCol: 16,
            wrapperCol: 8,
          },
          {
            name: '内边距',
            type: 'inputNumber',
            key: 'padding',
            labelCol: 16,
            wrapperCol: 8,
          },
          {
            name: '圆角值',
            type: 'inputNumber',
            key: 'radius',
            labelCol: 16,
            wrapperCol: 8,
          },
          {
            name: '背景颜色',
            type: 'colorPicker',
            key: 'bkColor',
            labelCol: 16,
            wrapperCol: 8,
          },
          {
            name: '视频',
            type: 'table',
            key: 'video',
            showFootButton: true,
            labelCol: 16,
            wrapperCol: 8,
            showPagination: false,
            dataSource: this.form.video,
            onAddData: () => {
              return { url: '' }
            },
            columns: [
              {
                title: '视频内容',
                dataIndex: 'url',
                customRender: function (text, records, index, h) {
                  return (
                    <FormUploadVideo
                      item={{}}
                      style={{ width: '100px', height: '100px', marginLeft: '-10px' }}
                      value={records.url}
                      onChange={(data) => {
                        records.url = data
                      }}
                    />
                  )
                },
              },
              {
                title: '操作',
                type: 'buttonGroup',
                align: 'center',
                typeData: ({ records, index }) => {
                  return [
                    {
                      name: '删除',
                      popTitle: '删除',
                      type: 'pop',
                      display: true,
                      isSafe: true,
                      onClick: () => {
                        this.form.data.splice(index, 1)
                        this.$forceUpdate()
                      },
                    },
                  ]
                },
              },
            ],
          },
        ]}
      />
    )
  },
}
</script>