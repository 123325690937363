<script>
import { getAction } from '@/command/netTool'
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      activityBackground: []
    }
  },
  watch: {
    activityBackground(newValue) {
      this.$emit('change', newValue)
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.activityBackground = this.item.dataSource.map(e => ({
        ...e,
        image2: e.monitorImg,
        select: this.value == e.monitorCode ? true : false
      }))
    },
    getValue() {
      return this.activityBackground
    },
    radioSelect(data) {
      // () => (e.select = !e.select)
      this.getValue().forEach(element => {
        element.select = false
      })
      data.select = !data.select
    },
    renderValue() {
      return (
        <div class="action-upload-img-group">
          {this.getValue().map(e => {
            const isSelect = e.select
            return (
              <div
                onClick={() => this.radioSelect(e)}
                class={`action-upload-item-${isSelect ? 'select' : 'normal'}`}
                style={{ backgroundImage: 'url(' + e.image2 + ')' }}
              ></div>
            )
          })}
        </div>
      )
    }
  },
  render() {
    return <div class="action-upload-main">{this.renderValue()}</div>
  }
}
</script>
<style lang="less" scoped>
.action-upload-main {
  display: flex;
  flex-wrap: wrap;
}
.action-upload-img-group {
  display: flex;
  flex-wrap: wrap;
  img {
    width: 80px;
    height: 100px;
  }
}
.action-upload-item-normal {
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 100px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  justify-content: flex-end;
  padding-bottom: 20px;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
}
.action-upload-item-select:extend(.action-upload-item-normal) {
  border: 3px solid rgba(44, 140, 240, 1);
  border-radius: 3px;
}
.action-uploader {
  background: #f5f5f5;
  width: 80px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
}
</style>
