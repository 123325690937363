// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import i18n from './locales'
// import { VueAxios } from './utils/request'
import ProLayout, { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import themePluginConfig from '../config/themePluginConfig'
// import * as Sentry from '@sentry/vue'
// import { Integrations } from '@sentry/tracing'

// import * as fundebug from 'fundebug-javascript'
// import fundebugVue from 'fundebug-vue'
// fundebug.apikey = '42b9e1687dd52f1026ea21e9a1731789ef17df8bd51dba25db7a78233d45e632'
// fundebugVue(fundebug, Vue)

// Sentry.init({
//   Vue,
//   dsn: 'http://e7da74de58b444948df881c868871ac0@192.167.5.100:9000/14',
//   integrations: [new Integrations.BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0
// })
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
import './mock'
import auth from './utils/auth'

import bootstrap from './core/bootstrap'
import './core/lazy_use' // use lazy load components
import './permission' // permission control
import './utils/filter' // global filter
import './global.less' // global style
import './assets/fonts/iconfont.css'

// let orignalSetItem = sessionStorage.setItem
// sessionStorage.setItem = function (key, newValue) {
//   // 要监听的key为openKeys
//   if (key === 'openKeys') {
//     let setItemEvent = new Event('setItemEvent')
//     setItemEvent.newValue = newValue
//     window.dispatchEvent(setItemEvent)
//     orignalSetItem.apply(this, arguments)
//   }
// }

// mount axios to `Vue.$http` and `this.$http`
// Vue.use(VueAxios)
// use pro-layout components
Vue.component('pro-layout', ProLayout)
Vue.component('page-container', PageHeaderWrapper)
Vue.component('page-header-wrapper', PageHeaderWrapper)
Vue.use(auth)
window.umi_plugin_ant_themeVar = themePluginConfig.theme

new Vue({
  router,
  store,
  i18n,
  // init localstorage, vuex
  created: bootstrap,
  render: (h) => h(App),
}).$mount('#app')
