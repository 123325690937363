// const apiTool = require('./apiTool')
import { Message } from 'ant-design-vue'
import apiTool from './apiTool'

export const getParams = function getParams(params = {}) {
  let text = ''

  Object.keys(JSON.parse(JSON.stringify(params))).forEach((e) => {
    if (params[e] !== undefined && params[e] !== 'null') {
      //text = text + e + '=' + params[e] + '&'
      text = (text ? `${text}&` : '') + e + '=' + params[e]
    }
  })
  if (text) {
    text = '?' + text
  }
  return text
}

export const getHeaders = function () {
  const token = localStorage.getItem('Access-Token')?.replace(/"/g, '') || ''
  return {
    'Content-Type': 'application/json',
    VERSION: localStorage.getItem('VERSION') || undefined,
    appCode: localStorage.getItem('appCode') || undefined,
    Authorization: `bearer ${token}`,
    'X-Application-name': 'app',
  }
}

export const postAction = function (url, params = {}, host = '/api', headers = null) {
  return new Promise((resolve, reject) => {
    fetch(host + url, {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(params),
      headers: headers || getHeaders(),
    })
      .then((e) => {
        return new Promise(async (resolve) => {
          resolve({
            ...(await e.json()),
            status: e.status,
          })
        })
      })
      .then((e) => {
        if (e.status == 401) {
          apiTool.jumpLogin()
        } else if (e.code == 200) {
          resolve(e)
        } else {
          console.log(e,"codecodecodecodecode")
          Message.error(e.msg)
          reject(e)
        }
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const putAction = function (url, params = {}, host = '/api') {
  return new Promise((resolve, reject) => {
    fetch(host + url, {
      credentials: 'include',
      method: 'PUT',
      body: JSON.stringify(params),
      headers: getHeaders(),
    })
      .then((e) => {
        return new Promise(async (resolve) => {
          resolve({
            ...(await e.json()),
            status: e.status,
          })
        })
      })
      .then((e) => {
        if (e.status == 401) {
          apiTool.jumpLogin()
        } else if (e.code == 200) {
          resolve(e)
        } else {
          Message.error(e.msg)
          reject(e)
        }
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const getAction = function (url, params, host = '/api') {
  return new Promise((resolve, reject) => {
    fetch(host + url.replace('/api', '') + getParams(params), {
      credentials: 'include',
      method: 'GET',
      headers: getHeaders(),
    })
      .then((e) => {
        return new Promise(async (resolve) => {
          resolve({
            ...(await e.json()),
            status: e.status,
          })
        })
      })
      .then((e) => {
        if (e.status == 401) {
          apiTool.jumpLogin()
        } else if (e.code == 200) {
          resolve(e)
        } else {
          Message.error(e.msg)
          reject(e)
        }
      })
      .catch((e) => {
        reject(e)
      })
  })
}

// 删除
export const deleteAction = function (url, params = {}, host = '/bpi') {
  return new Promise((resolve, reject) => {
    fetch(host + url, {
      credentials: 'include',
      method: 'DELETE',
      body: JSON.stringify(params),
      headers: getHeaders(),
    })
      .then((e) => {
        return new Promise(async (resolve) => {
          resolve({
            ...(await e.json()),
            status: e.status,
          })
        })
      })
      .then((e) => {
        if (e.status == 401) {
          apiTool.jumpLogin()
        } else if (e.code == 200) {
          resolve(e)
        } else {
          Message.error(e.msg)
          reject(e)
        }
      })
      .catch((e) => {
        reject(e)
      })
  })
}
// 删除
export const delAction = function (url, params = {}, host = '/api') {
  return new Promise((resolve, reject) => {
    fetch(host + url, {
      credentials: 'include',
      method: 'DELETE',
      body: JSON.stringify(params),
      headers: getHeaders(),
    })
      .then((e) => {
        return new Promise(async (resolve) => {
          resolve({
            ...(await e.json()),
            status: e.status,
          })
        })
      })
      .then((e) => {
        if (e.status == 401) {
          apiTool.jumpLogin()
        } else if (e.code == 200) {
          resolve(e)
        } else {
          Message.error(e.msg)
          reject(e)
        }
      })
      .catch((e) => {
        reject(e)
      })
  })
}

// importexcel文件
export const importAction = function (url) {
  return new Promise((resolve, reject) => {
    const file = document.createElement('input')
    file.type = 'file'
    file.onchange = (e) => {
      const fileContent = e.target.files[0]
      const fileName = fileContent.name
      const formData = new FormData()
      formData.append('file', fileContent, fileName)
      // 导入excel到服务器
      const token = localStorage.getItem('Access-Token').replace(/"/g, '')
      fetch(url, {
        method: 'POST',
        body: formData,
        redirect: 'follow',
        headers: {
          Authorization: `bearer ${token}`,
          appCode: localStorage.getItem('appCode'),
        },
      })
        .then((e) => e.json())
        .then((res) => {
          if (res.code == 200) {
            resolve(res)
          } else {
            reject(res)
          }
        })
        .catch((err) => {
          reject(err)
        })
    }
    file.click()
  })
}

// 文件下载
export const downloadAction = function (url, fileName) {
  const token = localStorage.getItem('Access-Token').replace(/"/g, '')
  fetch(url, {
    headers: getHeaders(),
  })
    .then((e) => e.blob())
    .then((e) => {
      const a = document.createElement('a')
      a.href = URL.createObjectURL(e)
      a.download = fileName
      a.click()
    })
}

export default {
  postAction,
  getAction,
  putAction,
  deleteAction,
  delAction,
  importAction,
}
