<script>
import { Timeline } from 'ant-design-vue'
export default {
  props: {
    typeData: {
      type: Array,
      default: () => []
    }
  },
  //   data() {
  //     return {
  //       lineList: []
  //     }
  //   },
  //   watch: {
  //     typeData: {
  //       handler(newValue, oldValue) {
  //         if (newValue instanceof Array && newValue.length) {
  //           //   console.log(newValue, oldValue)
  //           //   console.log('typeData正在被侦听')
  //           this.lineList = newValue
  //         }
  //       },
  //       deep: true,
  //       immediate: true
  //     }
  //   },
  render() {
    return (
      <Timeline>
        {this.typeData.map(e => {
          return (
            <Timeline.Item>
              {e.name}
              <br />
              {e.value}
            </Timeline.Item>
          )
        })}
      </Timeline>
    )
  }
}
</script>
