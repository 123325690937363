import Vue from 'vue'

export default Vue.directive('auth', {
  inserted: (el, binding, vNode) => {
    const access = JSON.parse(localStorage.getItem('userAuth')) || []
    let { value } = binding
    if (!value) {
      remove(el)
      return console.error('please set the value to a string.')
    } else {
      const permissions = value.split(',')
      const hasPermission = permissions.some(cur => access.includes(cur))

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    }
  }
})
