<script>
export default {
  props: ['item', 'value', 'mode'],
  methods: {},
  render() {
    return (
      <div>
        {this.item.list.map((item) => {
          if (item.reply === '0') return
          return (
            <div
              style={{
                marginTop: '8px',
                background: '#f5f5f5',
                padding: '0px 6px',
                borderRadius: '5px',
              }}
            >
              <span style={{ color: '#2b86f6' }}>{item.reply === '1' ? '商家回复' : item.nickname}: </span>
              {item.content}
            </div>
          )
        })}
      </div>
    )
  },
}
</script>
