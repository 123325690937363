<script>
import { Radio, Button } from 'ant-design-vue'
export default {
  props: {
    typeData: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    if (this.item.defaultValue !== undefined) {
      this.$emit('change', this.item.defaultValue)
    }
  },
  methods: {
    renderButton(item) {
      return (
        <div class="radio-item">
          <Button
            style={{
              marginRight: '10px',
              marginTop: '3px',
              padding: '0px 5px',
              width: 'fit-content',
              ...item.styles,
              ...this.item.styles,
            }}
          >
            <Radio value={item.value} disabled={item.disabled}>
              {item.name}
            </Radio>
          </Button>
          {item.hint && <p>{item.hint}</p>}
        </div>
      )
    },
    onChange(value) {
      if (this.item.onChange) {
        this.item.onChange(value)
      }
      this.$emit('change', value)
    },
  },
  render() {
    return (
      <Radio.Group
        disabled={this.mode == 'detail' || this.item.disabled}
        value={this.value}
        onChange={(e) => this.onChange(e.target.value)}
        style={{ display: 'flex', height: '100%', flexWrap: 'wrap' }}
      >
        {(this.typeData || []).map((e) => this.renderButton(e))}
      </Radio.Group>
    )
  },
}
</script>
<style lang="less" scoped>
.radio-item {
  display: flex;
  flex-direction: column;
  p {
    display: inline-block;
    margin-top: 10px;
    margin-right: 12px;
    color: #b6b6b6;
  }
}
</style>
