<script>
import { Select } from 'ant-design-vue'
export default {
  props: ['item', 'typeData', 'value', 'mode'],
  mounted() {
    if (this.item.defaultValue !== undefined) {
      this.$emit('change', this.value || this.item.defaultValue)
    }
    const dom = document.querySelector('.detail-form-group')
    if (dom) {
      dom.addEventListener('scroll', () => {
        this.$refs.select.blur()
      })
    }
  },
  methods: {
    getValue() {
      if (!this.value) return []
      if (this.item.sort) {
        return [].concat(this.value).sort((a, b) => Number(a) - Number(b))
      } else {
        if (typeof this.value == 'string') {
          return this.value.split(',')
        }
        return this.value
      }
    },
    onChange(e) {
      if (this.item.onChange) {
        this.item.onChange(e)
      }
      this.$emit('changeSelect', e)
      this.$emit('change', e)
    },
  },
  render() {
    return (
      <Select
        ref="select"
        props={this.item.props}
        disabled={this.mode == 'detail' || this.item.disabled}
        value={this.getValue()}
        style={{ width: '100%' }}
        onChange={(e) => this.onChange(e)}
        placeholder={this.item.placeholder ?? '请选择' + (this.item.name || this.item.label)}
        onDropdownVisibleChange={this.item?.onDropdownVisibleChange}
        {...this.item.props}
      >
        {(this.typeData || []).map((e) => {
          return (
            <Select.Option value={e.value} disabled={e.disabled || false}>
              {e.name}
              {e.custom && '(自定义)'}
            </Select.Option>
          )
        })}
      </Select>
    )
  },
}
</script>
