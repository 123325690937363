<script>
import { getAction } from '@/command/netTool'
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      select: this.value || '',
      activityBackground: [],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      getAction('/api/farmShowSpecialTemplate/getList').then((e) => {
        this.activityBackground =
          e.data.map((e) => ({
            ...e,
            image2: e.templateImg,
            name: e.templateName,
          })) || []
      })
    },
    radioSelect(data) {
      this.$emit('change', data.id)
    },
    renderValue() {
      const list = this.item.filter ? this.activityBackground.filter(this.item.filter) : this.activityBackground
      return (
        <div class="action-upload-img-group">
          {list.map((e) => {
            const isSelect = e.id == this.value
            return (
              <aside style="text-align: center">
                <div
                  onClick={() => this.radioSelect(e)}
                  class={`action-upload-item-${isSelect ? 'select ' : 'normal'}`}
                  style={{ backgroundImage: 'url(' + e.image2 + ')' }}
                ></div>
                {this.item.is_show && <span>{e.name}</span>}
              </aside>
            )
          })}
        </div>
      )
    },
  },
  render() {
    return <div class="action-upload-main">{this.renderValue()}</div>
  },
}
</script>
<style lang="less" scoped>
.bg-box {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.action-upload-main {
  display: flex;
  flex-wrap: wrap;
}
.action-upload-img-group {
  display: flex;
  flex-wrap: wrap;
  img {
    width: 80px;
    height: 100px;
  }
}
.action-upload-item-normal {
  display: flex;
  flex-direction: column;
  width: 80px;
  height: 100px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;

  &:hover {
    .bg-box {
      display: flex;
      background-color: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;

      .anticon-form,
      .anticon-delete {
        color: #ddd;
      }
    }
  }
}
.action-upload-item-select:extend(.action-upload-item-normal) {
  border: 3px solid rgba(44, 140, 240, 1);
  border-radius: 3px;
}
.action-uploader {
  background: #f5f5f5;
  width: 80px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
}
</style>
