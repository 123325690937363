<script>
import { Cascader } from 'ant-design-vue'
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {},
    },
    typeData: {
      type: Array,
      default: () => [],
    },
    setValue: {
      type: Function,
      default: null,
    },
  },
  methods: {
    change(value, data) {
      console.log('data', value, data)
      if (this.item.onChange) {
        this.item.onChange(value)
      }
      this.$emit('change', value)
      if (this.item.nameKey) {
        this.item.nameKey.forEach((e, i) => {
          this.setValue(e, data[i].name)
        })
      }
      if (this.item.keyData) {
        this.item.keyData.forEach((e, i) => {
          this.setValue(e, data[i].id)
        })
      }
    },
  },
  render() {
    console.log(this.value,"cascadercascader")
    return (
      <Cascader
        onChange={(value, selectedOptions) => this.change(value, selectedOptions)}
        value={this.value}
        options={this.typeData}
        placeholder={this.item.placeholder ? this.item.placeholder : '请选择' + (this.item.name || this.item.label)}
        // style={{ width: '100%' }}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.form-cascader {
  width: 100%;
}
</style>
