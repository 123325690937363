<script>
import { Popconfirm, Button } from 'ant-design-vue'
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    onClick() {
      if (this.item.onClick) {
        this.item.onClick()
      }
    }
  },
  render() {
    return (
      <Popconfirm
        icon={this.item.popConfirm.icon}
        title={this.item.popConfirm.popTitle}
        ok-text={this.item.popConfirm.okText || '确认'}
        cancel-text={this.item.popConfirm.cancelText || '取消'}
        onConfirm={() => this.onClick()}
      >
        <Button
          props={this.item.props}
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '3px',
            ...(this.item.props || {}).style
          }}
          {...this.item.props}
        >
          {this.item.label}
        </Button>
      </Popconfirm>
    )
  }
}
</script>
