<script>
import api from '@/command/api'
import FormDropDownInput from '../FormDropDownInput'
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Object,
      default: () => {}
    },
    setValue: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      typeData: []
    }
  },
  methods: {
    async onInputSearch(value) {
      let valueSearch = value ? value : ''
      let searchResult = await api.command.search.call(this, {
        url: this.item.url + `${this.item.url.indexOf('?') !== -1 ? '&' : '?'}name=${valueSearch}`
      })
      this.typeData = searchResult.map(el => {
        return {
          name: el.mainBodyName || el.name || el.showName,
          value: el.liveRoomId || el.id
        }
      })
    },
    onMenuClick(select) {
      this.setValue(this.item.valueKey, select.value)
    }
  },
  render() {
    return (
      <FormDropDownInput
        typeData={this.typeData}
        item={{
          ...this.item,
          inputProps: {},
          onInputSearch: this.onInputSearch,
          onMenuClick: this.onMenuClick
        }}
        value={this.value}
        onChange={value => this.$emit('change', value)}
      />
    )
  }
}
</script>
