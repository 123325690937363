import api from '@/command/api'
import request from '@/utils/request'
export const getState = function getState() {
  return {
    ...api.command.getState(),
    // banner类型
    bannerType: []
  }
}

/**
 * 获取type类型
 */
export const getType = function getType() {
  request({
    url: '/bannerType/page',
    method: 'GET',
    params: {
      current: 1,
      size: 1000
    }
  }).then(e => {
    this.bannerType = e.result.records.map(e => ({
      name: e.typeName,
      value: e.code
    }))
  })
}
export default {
  getType,
  getState
}
