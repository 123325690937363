<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  render() {
    return (
      <div
        class="form-text"
        style={{ justifyContent: this.item.align, ...this.item.styles }}
        domProps={{ innerHTML: this.value || this.item.value }}
      >
        {this.value || this.item.value}
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.form-text {
  min-width: 30px;
  color: #515a6e;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
