import storage from 'store'
import { login, getInfo, logout, getCode, loginByCode, getCodeFind, updatePwd } from '@/api/login'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { welcome } from '@/utils/util'
import { delCookie } from '@/utils/cookie'

const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    info: {},
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((response) => {
            const result = response.result
            if (result.access_token) {
              storage.set(ACCESS_TOKEN, result.access_token.replace(/\"/g, ''), 7 * 24 * 60 * 60 * 1000)
              commit('SET_TOKEN', result.access_token)
              resolve(result)
            } else {
              reject(result)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 登录
    LoginByCode({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByCode(userInfo)
          .then((response) => {
            const result = response.result
            if (result.access_token) {
              storage.set(ACCESS_TOKEN, result.access_token.replace(/\"/g, ''), 7 * 24 * 60 * 60 * 1000)
              commit('SET_TOKEN', result.access_token)
            }
            resolve(result)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取登录验证码
    GetCode({ commit }, phoneNumber) {
      return new Promise((resolve, reject) => {
        getCode(phoneNumber)
          .then((response) => {
            const result = response.result
            resolve(result)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取找回密码验证码
    GetCodeFind({ commit }, phoneNumber) {
      return new Promise((resolve, reject) => {
        getCodeFind(phoneNumber)
          .then((response) => {
            const result = response.result
            resolve(result)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取找回密码验证码
    UpdatePwd({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        updatePwd(userInfo)
          .then((response) => {
            const result = response.result
            resolve(result)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((response) => {
            const result = response.result

            if (result.role && result.role.permissions.length > 0) {
              const role = result.role
              role.permissions = result.role.permissions
              role.permissions.map((per) => {
                if (per.actionEntitySet != null && per.actionEntitySet.length > 0) {
                  const action = per.actionEntitySet.map((action) => {
                    return action.action
                  })
                  per.actionList = action
                }
              })
              role.permissionList = role.permissions.map((permission) => {
                return permission.permissionId
              })
              commit('SET_ROLES', result.role)
              commit('SET_INFO', result)
            } else {
              reject(new Error('getInfo: roles must be a non-null array !'))
            }

            commit('SET_NAME', { name: result.name, welcome: welcome() })
            commit('SET_AVATAR', result.avatar)
            resolve(response)
          })
          .catch((error) => {
            console.log(error, 'error')
            reject(error)
          })
      })
    },

    // 免登录
    GetToken({ commit }, code, appCode = 'nqb') {
      return new Promise((resolve, reject) => {
        fetch(`/bpi/auth/social/token/wx?wxcode=${code}`, {
          method: 'POST',
          headers: {
            Authorization: 'Basic c3puY19hcHA6OThkMzVjODEtOGYwZS00MjM4LWJjODYtYjkwMjYwMmVlZTAz',
            appCode: appCode,
            regSource: '1',
            //   clientId: 'sznc_app',
          },
        })
          .then((e) => e.json())
          .then((result) => {
            if (result.access_token) {
              storage.set(ACCESS_TOKEN, result.access_token.replace(/\"/g, ''), 7 * 24 * 60 * 60 * 1000)
              commit('SET_TOKEN', result.access_token)
            }
            resolve(result)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 登出
    Logout({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token)
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            delCookie('access_token')
            storage.remove(ACCESS_TOKEN)
            resolve()
          })
          .catch(() => {
            resolve()
          })
          .finally(() => {})
      })
    },
  },
}

export default user
