<script>
import { Chrome } from 'vue-color'
import { Popconfirm } from 'ant-design-vue'
export default {
  props: ['item', 'value', 'mode'],
  data() {
    return {
      data: '#000',
    }
  },
  watch: {
    value() {
      if (this.data === this.value) return
      this.data = this.value || '#000'
    },
  },
  emits: ['change'],
  mounted() {
    this.data = this.value || '#000'
  },
  methods: {
    onChange(e) {
      if (this.item.onChange) {
        this.item.onChange(this.data.hex)
      }
      this.$emit('change', String(this.data.hex))
    },
  },
  render() {
    return (
      <div>
        <Popconfirm
          ok-text="确认"
          icon={() => <div></div>}
          onConfirm={this.onChange}
          title={() => <Chrome class="popconfirm" v-model={this.data} />}
        >
          <div class="color-preview" style={{ backgroundColor: this.value || '#000' }}></div>
        </Popconfirm>
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.color-preview {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-left: 10px;
  margin-top: 10px;
  border: 1px solid #eee;
}
.popconfirm {
  margin-left: -20px;
}
</style>
