import { getAction, putAction } from '@/command/netTool'
import request from '@/utils/request'
import { Message } from 'ant-design-vue'
import { getList } from './command'
/**
 * 关闭订单
 */
export const close = function close({ url, params = {} } = {}) {
  return new Promise(resolve => {
    request
      .put(url, params)
      .then(e => {
        Message.success('关闭订单成功')
        getList.call(this)
        resolve(e)
      })
      .catch(() => {
        Message.error('修改订单失败')
      })
  })
}

/**
 * 批量关闭订单
 */
export const batchClose = function close({ url, params = {} } = {}) {
  return new Promise(resolve => {
    putAction(url, params).then(e => {
      Message.success('关闭订单成功')
      getList.call(this)
      resolve(e)
    })
  })
}

/**
 * 退款
 */
export const refund = function refund({ url, params = {} } = {}) {
  return new Promise(resolve => {
    putAction(url, params).then(e => {
      if (e.data != 5) {
        Message.success('退款成功')
      } else {
        Message.error('退款失败')
      }
      getList.call(this)
      resolve(e)
    })
  })
}

/**
 * 核销
 */
export const refundOff = function refund({ url, params = {} } = {}) {
  return new Promise(resolve => {
    putAction(url, params).then(e => {
      if (e.data != 5) {
        Message.success('核销成功')
      } else {
        Message.error('核销失败')
      }
      getList.call(this)
      resolve(e)
    })
  })
}

/**
 * 发货
 */
export const delivery = function delivery({ url, params = {} } = {}) {
  return new Promise(resolve => {
    putAction(url, params).then(e => {
      Message.success('发货成功')
      getList.call(this)
      resolve(e)
    })
  })
}

/**
 * 发货
 */
export const received = function delivery({ url, params = {} } = {}) {
  return new Promise(resolve => {
    putAction(url, params).then(e => {
      Message.success('收货成功')
      getList.call(this)
      resolve(e)
    })
  })
}

/**
 * 核销
 */
export const hx = function hx({ url, params = {}, isPost = true } = {}) {
  return new Promise((resolve, reject) => {
    putAction(url, params).then(e => {
      Message.success('核销成功 数量 : ' + params.idList.length)
      isPost && getList.call(this)
      resolve(e)
    })
  })
}

/**
 * 确认有房
 */
export const haveRoom = function haveRoom({ url, params = {} }) {
  return new Promise((resolve, reject) => {
    getAction(url, params).then(e => {
      Message.success('操作成功')
      getList.call(this)
      resolve(e)
    })
  })
}

/**
 * @description 确认有票
 */
export const haveTicket = function haveTicket({ url, params = {} }) {
  return new Promise((resolve, reject) => {
    getAction(url, params).then(e => {
      Message.success('操作成功')
      getList.call(this)
      resolve(e)
    })
  })
}

/**
 * @description 无票库存
 */
export const noTicket = function noTicket({ url, params = {} }) {
  return new Promise((resolve, reject) => {
    getAction(url, params).then(e => {
      Message.success('操作成功')
      getList.call(this)
      resolve(e)
    })
  })
}

export default {
  close,
  batchClose,
  refund,
  refundOff,
  delivery,
  received,
  hx,
  haveRoom,
  haveTicket,
  noTicket
}
