<script>
import Tree from '@/components/Tree/Tree'
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    typeData: {
      type: Array,
      default: () => [],
    },
  },
  render() {
    return <Tree checkable tree-data={this.typeData}></Tree>
  },
}
</script>
