<script>
import { asyncRouterMap } from '@/config/router.config'
export default {
  name: 'route-view',
  props: {
    keepAlive: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      include: [],
      exclude: [],
      show: true
    }
  },
  mounted() {
    if (process.env.NODE_ENV === 'production') {
      this.deepRouter(asyncRouterMap)
    }
  },
  methods: {
    deepRouter(data = asyncRouterMap) {
      data.forEach(e => {
        if (e.hidden) {
          this.exclude.push(e.name)
        } else if (!e.redirect) {
          this.include.push(e.name)
        }
        if (e.children) {
          this.deepRouter(e.children)
        }
      })
    }
  },
  // watch: {
  //   $route(newValue) {
  //     this.show = false
  //     if (newValue.meta.keepAlive) {
  //       if (!this.include.includes(newValue.name)) {
  //         this.include.push(newValue.path)
  //       }
  //     } else {
  //       if (!this.exclude.includes(newValue.name)) {
  //         this.exclude.push(newValue.path)
  //       }
  //     }
  //     this.$nextTick(() => {
  //       this.show = true
  //     })
  //   }
  // },
  // created() {
  //   if (this.$route.meta.keepAlive) {
  //     this.include.push(this.$route.name)
  //   } else {
  //     this.exclude.push(this.$route.name)
  //   }
  // },
  render() {
    // console.log('include', this.include)
    // console.log('exclude', this.exclude)
    const {
      $route: { meta },
      $store: { getters }
    } = this
    const inKeep = (
      <keep-alive include={this.include} exclude={this.exclude}>
        <router-view />
      </keep-alive>
    )
    return inKeep
    // if (this.show) {
    //   return inKeep
    // } else {
    //   return null
    // }
  }
}
</script>
