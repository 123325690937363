<script>
import { Button, Input } from 'ant-design-vue'
export default {
  props: {
    maxValue: {
      type: Number,
      default: null,
    },
    value: {
      type: String | Number,
      default: '0',
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.$emit('change', '1')
  },
  methods: {
    //  增加
    onIncrease() {
      if (this.item.hasOwnProperty('maxValue')) {
        this.value =
          Number(this.value) + 1 > Number(this.item.maxValue) ? Number(this.item.maxValue) : Number(this.value) + 1
      } else {
        this.value = Number(this.value) + 1
      }
      if (this.item.onChange) {
        this.item.onChange(this.value)
      }
      this.$emit('change', this.value)
    },
    // 减少
    onReduce() {
      if (Number(this.value) > 0) {
        this.value = Number(this.value) - 1
        if (this.item.onChange) {
          this.item.onChange(this.value)
        }
        this.$emit('change', this.value)
      }
    },
  },
  render() {
    return (
      <div class="form-stepper" style={this.item.styles}>
        <Button class="stepper-button" onClick={this.onReduce}>
          -
        </Button>
        <Input style={{ width: '40px', textAlign: 'center' }} value={this.value} />
        <Button class="stepper-button" onClick={this.onIncrease}>
          +
        </Button>
      </div>
      // {this.item.desc && <span domProps={{ innerHTML: this.item.desc }} />}
    )
  },
}
</script>
<style lang="less" scoped>
.form-stepper {
  display: flex;
  align-items: center;
  span {
    font-size: 10px;
    margin-left: 10px;
    line-height: 20px;
  }
}
.stepper-button {
  background: rgba(247, 247, 248);
  &:nth-child(1) {
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &:nth-child(3) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
</style>
