<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  render() {
    return (
      <div
        class="form-text"
        style={{ justifyContent: this.item.align, ...this.item.styles }}
      >
        <p>{this.item.title}</p>
        <p>{this.value || this.item.value}</p>
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.form-text {
  min-width: 30px;
  color: #515a6e;
  font-size: 14px;
  p {
    margin: 0;
    &:nth-child(2) {
      font-size: 24px;
    }
  }

}
</style>
