<script>
import { Popconfirm, Button } from 'ant-design-vue'
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    onClick: {
      type: Function,
      default: null,
    },
  },
  methods: {
    renderButton(event) {
      return (
        <Button
          props={this.item}
          on={{
            click: event,
          }}
          {...this.item}
          style={{ marginRight: '10px' }}
        >
          {this.item.name}
        </Button>
      )
    },
  },
  render() {
    if (!this.item.isPop) return this.renderButton(this.$listeners.click)
    return (
      <Popconfirm
        icon={this.item.icon}
        title={this.item.popTitle}
        ok-text={this.item.okText || '确认'}
        cancel-text={this.item.cancelText || '取消'}
        onConfirm={this.$listeners.click}
        placement={this.item.popTitle.includes('删除') ? 'topRight' : undefined}
      >
        {this.renderButton()}
      </Popconfirm>
    )
  },
}
</script>
