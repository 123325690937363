<script>
import { getAction } from '@/command/netTool'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      formData: {},
      activityBackground: [],
      arrData: []
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (!this.init && newValue && typeof newValue == 'string') {
          this.init = true
          const keys = typeof newValue == 'string' ? newValue.split(',') : []
          this.getList({ select: keys })
        }
      },
      immediate: false
    },
    'item.shopId'(a, b) {
      if (a) {
        this.getList()
      }
    },
    activityBackground(newValue) {
      this.$emit('change', newValue)
    }
  },
  mounted() {
    this.arrData = this.item.setShowValue ? this.item.setShowValue.split(',') : []
    this.getList()
  },
  methods: {
    getList() {
      getAction(
        `/api/baseMonitor/${
          this.item.setFirm ? 'queryBaseMonitorListByFirmId?firmId' : 'queryBaseMonitorList?shopId'
        }=` + this.item.shopId
      ).then(e => {
        this.activityBackground = e.data.map(e => ({
          ...e,
          image2: e.monitorImg,
          select: this.item.setValue == e.monitorCode ? true : false,
          isOpen: this.arrData.includes(e.monitorCode) ? true : false
        }))
      })
    },
    getValue() {
      return this.activityBackground
    },
    radioSelect(data) {
      this.getValue().forEach(element => {
        element.select = false
      })
      data.select = !data.select
    },
    renderValue() {
      let that = this
      return (
        <div class="action-upload-img-group">
          {that.getValue().map(e => {
            const isSelect = e.select
            return (
              <div class="v_box">
                <div
                  onClick={() => that.radioSelect(e)}
                  class={`action-upload-item-${isSelect ? 'select' : 'normal'}`}
                  style={{ backgroundImage: 'url(' + e.image2 + ')' }}
                ></div>
                {that.item.setFirm ? (
                  <a-switch
                    style={{
                      marginLeft: '-16px'
                    }}
                    checked={e.isOpen}
                    onChange={() => (e.isOpen = !e.isOpen)}
                  />
                ) : (
                  ''
                )}
              </div>
            )
          })}
        </div>
      )
    }
  },
  render() {
    return <div class="action-upload-main">{this.renderValue()}</div>
  }
}
</script>
<style lang="less" scoped>
.v_box {
  text-align: center;
}
.action-upload-main {
  display: flex;
  flex-wrap: wrap;
}
.action-upload-img-group {
  display: flex;
  flex-wrap: wrap;
  img {
    width: 80px;
    height: 100px;
  }
}
.action-upload-item-normal {
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 100px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  justify-content: flex-end;
  padding-bottom: 20px;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
}
.action-upload-item-select:extend(.action-upload-item-normal) {
  border: 3px solid rgba(44, 140, 240, 1);
  border-radius: 3px;
}
.action-uploader {
  background: #f5f5f5;
  width: 80px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
}
</style>
