<script>
import { Input } from 'ant-design-vue'
export default {
  props: ['value', 'mode', 'item'],
  data() {
    return {
      data: '',
    }
  },
  watch: {
    value() {
      if (this.data === this.value) return
      this.data = this.value || ''
    },
  },
  mounted() {
    this.data = this.value || ''
  },
  render() {
    return (
      <div>
        <Input.TextArea
          disabled={this.mode == 'detail'}
          v-model={this.data}
          style={{ minHeight: this.item.minHeight || '200px' }}
          placeholder={this.item.placeholder ? this.item.placeholder : '请输入' + (this.item.name || this.item.label)}
          onChange={(e) => {
            this.$emit('change', e.target.value)
            this.value = e.target.value
          }}
          showCount={true}
          prop={this.item.props}
          maxLength={this.item.maxLength || 300}
        />
        {this.item.showCount && (
          <div class="showCount">
            {this.data.length || 0}/{this.item.maxLength || 300}
          </div>
        )}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.showCount {
  position: absolute;
  bottom: 0px;
  right: 15px;
  font-size: 12px;
  color: #ccc;
  user-select: none;
}
</style>