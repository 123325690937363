<script>
import { Upload, Modal } from 'ant-design-vue'
import draggable from 'vuedraggable'
export default {
  props: ['value', 'item', 'mode', 'styles'],
  components: { draggable },
  data() {
    return {
      previewVisible: false,
      previewImage: ''
    }
  },
  methods: {
    handlePreview(file) {
      if (!file.url) return
      this.previewImage = file.url
      this.previewVisible = true
    },
    getImgUrl(list) {
      const arr = []
      const { url } = JSON.parse(localStorage.getItem('qiniu')) || {}
      list.forEach(e => {
        if (e.status == 'done') {
          if (e.hash) {
            arr.push(url + e.hash)
          } else if (e.url) {
            arr.push(e.url)
          } else {
            if (e.response && e.response.hash) {
              arr.push(url + e.response.hash)
            }
          }
        }
      })
      return arr.join(',')
    },
    getFileList(url) {
      if (Array.isArray(url)) return url
      if (!url) return []
      return url
        .split(',')
        .filter(e => e)
        .map(e => {
          if (!e) return
          const name = e.split('?')[0].split('/')[3]
          return {
            uid: 'vc-upload-' + name,
            name,
            status: 'done',
            url: e,
            thumbUrl: e
          }
        })
    },
    handleChange(data) {
      let arr = data.fileList.slice(0, 20)
      if (arr.some(e => e.status == 'uploading')) {
        this.$emit('change', arr)
      } else {
        this.$emit('change', this.getImgUrl(arr))
      }
    },
    renderButton() {
      return (
        <div>
          <a-icon type="plus" />
          <div class="ant-upload-text">上传</div>
        </div>
      )
    },
    renderUploadItem(file) {
      if (file.indexOf('mp4') !== -1) {
        return <video src={file} />
      }
      return <img src={file} />
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
      if (!isJpgOrPng) {
        this.$message.error('请上传图片文件（jpeg/png/jpg）')
        return false
      }
      const isLt4M = file.size / 1024 / 1024 < 4
      if (isJpgOrPng && !isLt4M) {
        this.$message.error('图片资源文件体积不能超过4M')
      }
      return isJpgOrPng && isLt4M
    },
    onEnd({ oldIndex, newIndex }) {
      let old = this.value.split(',')
      let oldOne = old[oldIndex]
      let newItems = [...old]
      // 删除老的节点
      newItems.splice(oldIndex, 1)
      // 增加新的节点
      newItems.splice(newIndex, 0, oldOne)
      this.value = newItems.join(',')
      this.$emit('change', newItems.join(','))
    },
    handleRemove(obj, index) {
      let old = this.value.split(',')
      old.splice(index, 1)
      this.value = old.join(',')
      this.$emit('change', old.join(','))
    }
  },
  render() {
    const { token } = JSON.parse(localStorage.getItem('qiniu')) || {}
    return (
      <div>
        <draggable class="draggable-list" list={this.getFileList(this.value)} onEnd={this.onEnd}>
          {this.getFileList(this.value).map((e, index) => {
            return (
              <div class="item-box" key={index}>
                <img class="img-one" src={e.url} />
                <div class="item-box-list">
                  <div class="item-box-list-top">
                    <img class="img-three" src={require('./assets/drag.png')} />
                  </div>
                  <div class="item-box-list-bottom">
                    <img
                      class="img-three"
                      src={require('./assets/eye.png')}
                      onClick={() => this.handlePreview(e)}
                      style="margin-right: 10px"
                    />
                    <img
                      onClick={() => this.handleRemove(e, index)}
                      class="img-three"
                      src={require('./assets/del.png')}
                      style="margin-left: 10px"
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </draggable>
        <Upload
          name="file"
          action="//upload.qiniup.com/"
          list-type="picture-card"
          fileList={this.getFileList(this.value)}
          accept={'text/img'}
          disabled={this.mode == 'detail' || this.item.disabled}
          class="uploader"
          data={{ token }}
          onChange={this.handleChange}
          style={this.styles}
          renderUploadItem={this.renderUploadItem}
          beforeUpload={this.beforeUpload}
          onPreview={this.handlePreview}
          multiple={this.item.multiple}
        >
          {(this.getFileList(this.value) || []).length < (this.item.maxLength || 1) && this.renderButton()}
        </Upload>

        <Modal visible={this.previewVisible} footer={null} onCancel={() => (this.previewVisible = false)}>
          <img alt="example" style="width: 100%" src={this.previewImage} />
        </Modal>
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.uploader {
  margin-left: 0px !important;
}

/deep/.ant-upload-list-picture-card-container {
  display: none !important;
}

.draggable-list {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;

  .item-box {
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    text-align: center;
    line-height: 104px;
    position: relative;

    .img-one {
      width: 86px;
      height: 86px;
      object-fit: cover;
    }
    &:hover {
      .item-box-list {
        display: inline-block;
      }
    }

    .item-box-list {
      cursor: pointer;
      width: 87px;
      height: 87px;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 8px;
      top: 8px;
      display: none;

      .item-box-list-top {
        width: 100%;
        height: 20px;
        text-align: center;
        line-height: 20px;
        margin-top: 30%;

        .img-three {
          width: 16px;
          height: 16px;
        }
      }

      .item-box-list-bottom {
        width: 100%;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .img-three {
          width: 16px;
          height: 16px;
        }
      }

      .img-three {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
