<script>
import { Upload, Modal } from 'ant-design-vue'
export default {
  props: {
    value: {
      type: Array || String,
      default: () => {
        return []
      }
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    mode: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    styles: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      previewVisible: false,
      previewImage: ''
    }
  },
  methods: {
    handlePreview(file) {
      if (!file.url) return
      this.previewImage = file.url
      this.previewVisible = true
    },
    getImgUrl(list) {
      // '?imageView2/0/format/jpg/q/75'
      const arr = []
      const { url } = JSON.parse(localStorage.getItem('qiniu')) || {}
      list.forEach(e => {
        if (e.status == 'done') {
          if (e.hash) {
            arr.push(url + e.hash)
          } else if (e.url) {
            arr.push(e.url)
          } else {
            if (e.response && e.response.hash) {
              arr.push(url + e.response.hash)
            }
          }
        }
      })
      return arr.join(',')
    },
    getFileList(url) {
      if (Array.isArray(url)) return url
      if (!url) return []
      return url
        .split(',')
        .filter(e => e)
        .map(e => {
          if (!e) return
          const name = e.split('?')[0].split('/')[3]
          return {
            uid: 'vc-upload-' + name,
            name,
            status: 'done',
            url: e,
            thumbUrl: e
          }
        })
    },
    handleChange(data) {
      if (data.file.status == 'uploading') {
        this.$emit('change', data.fileList)
      } else {
        this.$emit('change', this.getImgUrl(data.fileList))
      }
    },
    renderButton() {
      return (
        <div>
          <a-icon type="plus" />
          <div class="ant-upload-text">上传</div>
        </div>
      )
    },
    renderUploadItem(file) {
      if (file.indexOf('mp4') !== -1) {
        return <video src={file} />
      }
      return <img src={file} />
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
      if (!isJpgOrPng) {
        this.$message.error('请上传图片文件（jpeg/png/jpg）')
        return false
      }
      const isLt4M = file.size / 1024 / 1024 < 4
      if (isJpgOrPng && !isLt4M) {
        this.$message.error('图片资源文件体积不能超过4M')
      }
      return isJpgOrPng && isLt4M
    }
  },
  render() {
    const { token } = JSON.parse(localStorage.getItem('qiniu')) || {}
    return (
      <div>
        <Upload
          name="file"
          action="//upload.qiniup.com/"
          list-type="picture-card"
          fileList={this.getFileList(this.value)}
          accept={'text/img'}
          disabled={this.mode == 'detail' || this.disabled}
          class="uploader"
          data={{ token }}
          onChange={this.handleChange}
          style={this.styles}
          renderUploadItem={this.renderUploadItem}
          beforeUpload={this.beforeUpload}
          onPreview={this.handlePreview}
        >
          {(this.getFileList(this.value) || []).length < (this.item.maxLength || 1) && this.renderButton()}
        </Upload>

        <Modal visible={this.previewVisible} footer={null} onCancel={() => (this.previewVisible = false)}>
          <img alt="example" style="width: 100%" src={this.previewImage} />
        </Modal>
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.uploader {
  margin-left: 0px !important;
}

/deep/.ant-upload-picture-card-wrapper.uploader {
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin: 0;
}

/deep/.ant-upload-list-picture-card-container {
  width: 60px !important;
  height: 60px !important;
  overflow: hidden;
  margin: 0;
}

/deep/.ant-upload.ant-upload-select-picture-card {
  width: 60px !important;
  height: 60px !important;
  overflow: hidden;
  margin: 0;
}

/deep/.ant-upload-list-item {
  width: 60px !important;
  height: 60px !important;
  overflow: hidden;
  margin: 0;
}

/deep/.anticon {
  font-size: 13px !important;
}
</style>
