<script>
import { Radio } from 'ant-design-vue'
export default {
  props: ['typeData', 'value', 'mode'],
  methods: {
    renderRadio(item) {
      return (
        <Radio
          value={item.value}
          disabled={item.disabled}
          style={{
            height: '50px',
            lineHeight: '50px',
          }}
        >
          {typeof item.name == 'string' && item.name}
          {typeof item.name == 'function' && item.name()}
        </Radio>
      )
    },
  },
  render() {
    return (
      <Radio.Group
        disabled={this.mode == 'detail'}
        value={this.value}
        onChange={e => this.$emit('change', e.target.value)}
        class="radioGroup"
      >
        {(this.typeData || []).map(e => this.renderRadio(e))}
      </Radio.Group>
    )
  }
}
</script>
<style lang="less" scoped>
.radioGroup {
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  padding-left: 10px;
}
</style>
