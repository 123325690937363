<script>
export default {
  props: ['item', 'value', 'mode'],
  watch: {
    'item.data'() {
      this.handleSendMessage()
    },
  },
  mounted() {
    setTimeout(this.handleSendMessage, 1500)
  },
  methods: {
    handleSendMessage() {
      const iframe = this.$refs.iframe
      const message = this.item.data
      iframe.contentWindow.postMessage(message, '*') // 发送消息
    },
  },
  render() {
    return (
      <div class="form-iframe">
        <iframe
          ref="iframe"
          style={this.item.styles}
          frameborder="0"
          scrolling="auto"
          src={this.item.url}
          width="375px"
          height="660px"
        ></iframe>
      </div>
    )
  },
}
</script>
<style lang="less">
.form-iframe {
  iframe {
    display: block;
  }
}
</style>
