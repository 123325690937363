<script>
import { Checkbox, Button } from 'ant-design-vue'
export default {
  props: {
    typeData: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    setChange(data) {
      if (this.item.onChange) {
        this.item.onChange(data)
      }
    },
    onCheckItem(item) {
      const arr = new Set(this.value)
      if (arr.has(item.value)) {
        arr.delete(item.value)
        this.$emit('change', Array.from(arr))
      } else {
        arr.add(item.value)
        this.$emit('change', Array.from(arr))
      }
    },
    renderButton(item) {
      return (
        <Button style={{ marginRight: '10px', marginTop: '3px', ...this.item.styles }}>
          <Checkbox disabled={item.disabled} value={item.value} onClick={() => this.onCheckItem(item)}>
            {item.name}
          </Checkbox>
        </Button>
      )
    }
  },
  render() {
    return (
      <Checkbox.Group
        disabled={this.mode == 'detail' || this.item.disabled}
        value={this.value}
        style={{ display: 'flex', height: '100%' }}
      >
        {(this.typeData || []).map(e => this.renderButton(e))}
      </Checkbox.Group>
    )
  }
}
</script>
