// eslint-disable-next-line no-unused-vars
import { Message } from 'ant-design-vue'
// eslint-disable-next-line no-unused-vars
import { getAction, postAction } from '../command/netTool'

/**
 * 获取状态
 */
export const getState = function getState() {
  return {
    // 树形列表
    treeData: [],
    // 所点击tree
    treeSelectObj: {},
    // 点击tree对应的右侧列表信息
    treeSelectList: [],
    // 右侧是否显示对应子节点 bool
    treeChildBool: false,
    // 父节点对应的子节点
    treeChildList: [],
    // 树形选择对应url
    treeSelectUrl: '',
    // 树选择详情
    treeDetail: {},
    // detailUrl
    detailUrl: '',
    // treeData
    treeNetData: {},
    // treeTypeData
    treeTypeData: [],
  }
}

/**
 * 获取树形
 */
export const getTreeSelectUrl = function getTreeSelectUrl(type) {
  switch (type) {
    case 'kingkong':
      return '/api/config/farmKingKong/list'
    default:
      break
  }
}

/**
 * 获取树形
 */
export const getTreeList = function getTreeList({ url, params } = {}) {
  const getTree = function (arr = []) {
    return arr.map((e) => ({
      ...e,
      title: e.name,
      key: e.code,
      children: e.childList ? getTree(e.childList) : [],
    }))
  }
  return new Promise((resolve) => {
    // getAction(url, params).then((e) => {
    this.treeData = getTree([
      {
        id: '1',
        parentId: '0',
        code: 'plate_info',
        name: '平台信息',
        type: '1',
        childFlag: '0',
        childList: null,
        detailList: null,
      },
      {
        id: '4',
        parentId: '0',
        code: 'farm_label',
        name: '云上大堰',
        type: '2',
        childFlag: '1',
        childList: [
          {
            id: '41',
            parentId: '4',
            code: 'kingkong',
            name: '金刚区',
            type: '1',
            childFlag: '0',
            childList: null,
            detailList: null,
          },
          {
            id: '42',
            parentId: '4',
            code: 'load_icon',
            name: '首页导航',
            type: '1',
            childFlag: '0',
            childList: null,
            detailList: null,
          },
        ],
      },
      {
        id: '77',
        parentId: '0',
        code: 'h5_home',
        name: 'H5首页',
        type: '1',
        childFlag: '0',
        childList: null,
        detailList: null,
      },
      {
        id: '81',
        parentId: '0',
        code: 'my_setting',
        name: '我的设置',
        type: '1',
        childFlag: '0',
        childList: null,
        detailList: null,
      },
      {
        id: '83',
        parentId: '0',
        code: 'order_handing_fee',
        name: '订单手续费',
        type: '1',
        childFlag: '0',
        childList: null,
        detailList: null,
      },
    ])
    this.setSelect(this.treeData[0])
    resolve()
    // })
  })
}

/**
 * 点击获取对应树形详情
 */
export const getTreeDetail = function getTreeDetail({ url = this.detailUrl, params } = {}) {
  return new Promise((resolve, reject) => {
    getAction('/api' + url, params)
      .then(async (e) => {
        this.treeNetData = e.data || {}
        if (this.onFetchInit) {
          const ret = (await this.onFetchInit(e.data || {})) || {}
          this.treeDetail = ret.form
          this.treeTypeData = ret.typeData
        } else {
          this.treeDetail = e.data || {}
        }
        this.detailUrl = url
        resolve()
      })
      .catch((e) => reject && reject(e))
  })
}

/**
 * 编辑/更新
 * @returns
 */
export const edit = function edit({ url, params } = {}) {
  return new Promise((resolve, reject) => {
    postAction(url, params, '/api')
      .then(() => {
        Message.success('修改成功')
        getTreeDetail.call(this)
        resolve()
      })
      .catch((e) => reject && reject(e))
  })
}

/**
 * 删除
 * @returns
 */
export const del = function edit({ url, params } = {}) {
  return new Promise((resolve, reject) => {
    postAction(url, params)
      .then(() => {
        Message.success('删除成功')
        getTreeDetail.call(this)
        resolve()
      })
      .catch((e) => reject && reject(e))
  })
}

/* 
  获取平台信息
 */
export const getPlatformInfo = function getPlatformInfo({ url, params } = {}) {
  return new Promise((resolve) => {
    getAction(url, params).then((e) => {
      if (e.code == 200) {
        resolve(e.data)
      } else {
        Message.error(e.msg)
      }
    })
  })
}

/**
 * 新建数据
 * @returns
 */
export const create = function create({ url, params } = {}) {
  return new Promise((resolve) => {
    postAction(url, params).then((e) => {
      if (e.code == 200) {
        resolve(e.data)
        getTreeDetail.call(this)
      } else {
        Message.error(e.msg)
      }
    })
  })
}

export default {
  getState,
  getTreeSelectUrl,
  getTreeList,
  getTreeDetail,
  edit,
  del,
  create,
  getPlatformInfo,
}
